import React, { useEffect, useState } from 'react';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { labels } from '@app/locales/constants'
import { AuthModal } from '../common/AuthModal';
import { SocialMediaType } from '@app/interfaces/interfaces';
import { authSocialMediaBtns } from '@app/constants/socialMediaIcons';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Link } from 'react-router-dom';

interface LoginFormProps {
  isSignUp?: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({isSignUp=false}) => {
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<SocialMediaType>();

  const openAuthModal = (socialMediaType: SocialMediaType) => {
    setSelectedSocialMedia(socialMediaType);
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const renderAvatar = (icon: string) => {
    return <S.IconAvatar size={18} src={icon} />;
  };

  const navLink = isSignUp ? "/auth/login" : "/auth/sign-up";
  const navLinkText = isSignUp ? labels.alreadyHaveAccount : labels.noAccount;
  
  return (
    <>
      {!isLoading && (
        <>
          <Auth.FormWrapper>
            <Auth.ContentCol xs={24} sm={16} md={12} lg={10} xl={8}>
              <Auth.FormCard>
                <Auth.Title>{isSignUp ? labels.signUp : labels.signIn }</Auth.Title>
                <Auth.Subtitle>{labels.joinNow}</Auth.Subtitle>
                <BaseRow>
                  <BaseDivider>
                    <Auth.FormTitle>{labels.continueWith}</Auth.FormTitle>
                  </BaseDivider>
                </BaseRow>
                <Auth.BtnGroupRow align="middle" justify="center">
                  {authSocialMediaBtns.map((item) => (
                    <BaseCol xs={24} key={item.id}>
                      <S.StyledButton
                        $textColor={item.textColor}
                        $backgroundColor={item.backgroundColor}
                        icon={renderAvatar(item.socialMediaIcon)}
                        size='large'
                        onClick={() => openAuthModal(item.socialMediaType)}
                      >
                        {item.title}
                      </S.StyledButton>
                    </BaseCol>
                  ))}
                </Auth.BtnGroupRow>
                <Auth.Text>
                  {navLinkText}{' '}
                  <Link to={navLink}>
                    <Auth.LinkText>{labels.here}</Auth.LinkText>
                  </Link>
                </Auth.Text>
              </Auth.FormCard>
            </Auth.ContentCol>
          </Auth.FormWrapper>
          <AuthModal
            isModalOpen={isModalOpen}
            socialMediaType={selectedSocialMedia}
            handleCancel={handleCancel}
            isSignUp={isSignUp}
          />
        </>
      )}
    </>
  );
};
