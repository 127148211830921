import { ThemeType } from '@app/interfaces/interfaces';
import { hexToRGB } from '@app/utils/utils';
import { css } from 'styled-components';
import { BASE_COLORS } from './constants';
import { darkColorsTheme, antDarkColorsTheme } from './dark/darkTheme';
import { lightColorsTheme } from './light/lightTheme';

export const themeObject = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

export const antThemeObject = {
  light: {},
  dark: antDarkColorsTheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primary};
  --primary1-color: ${themeObject[theme].primary1};
  --primary-gradient-color: ${themeObject[theme].primaryGradient};
  --info-color: var(--primary-color);
  --secondary-color: ${themeObject[theme].secondary};
  --error-color: ${themeObject[theme].error};
  --warning-color: ${themeObject[theme].warning};
  --success-color: ${themeObject[theme].success};
  --body-background-color: ${themeObject[theme].bodyBackground};
  --background-color: ${themeObject[theme].background};
  --secondary-background-color: ${themeObject[theme].secondaryBackground};
  --secondary-background-selected-color: ${themeObject[theme].secondaryBackgroundSelected};
  --additional-background-color: ${themeObject[theme].additionalBackground};
  --collapse-background-color: ${themeObject[theme].collapseBackground};
  --spinner-base-color: ${themeObject[theme].spinnerBase};
  --shadow-color: ${themeObject[theme].shadow};
  --border-color: ${themeObject[theme].border};
  --scroll-color: ${themeObject[theme].scroll};

  --primary-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --info-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --secondary-rgb-color: ${hexToRGB(themeObject[theme].secondary)};
  --error-rgb-color: ${hexToRGB(themeObject[theme].error)};
  --warning-rgb-color: ${hexToRGB(themeObject[theme].warning)};
  --success-rgb-color: ${hexToRGB(themeObject[theme].success)};
  --background-rgb-color: ${hexToRGB(themeObject[theme].background)};

  --text-main-color: ${themeObject[theme].textMain};
  --text-light-color: ${themeObject[theme].textLight};
  --text-superLight-color: ${themeObject[theme].textSuperLight};
  --text-secondary-color: ${themeObject[theme].textSecondary};
  --text-dark-color: ${themeObject[theme].textDark};
  --subtext-color: ${themeObject[theme].subText};

  --chart-tooltip-label-color: ${themeObject[theme].chartTooltipLabel};
  --chart-legend-label-color: ${themeObject[theme].chartLegendLabel};
  --chart-color-blue-full: ${themeObject[theme].chartColorBlueFull};
  --chart-color-blue-strong: ${themeObject[theme].chartColorBlueStrong};
  --chart-color-blue-medium: ${themeObject[theme].chartColorBlueMedium};
  --chart-color-blue-light: ${themeObject[theme].chartColorBlueLight};
  --chart-color-aqua-full: ${themeObject[theme].chartColorAquaFull};
  --chart-color-aqua-strong: ${themeObject[theme].chartColorAquaStrong};
  --chart-color-aqua-medium: ${themeObject[theme].chartColorAquaMedium};
  --chart-color-aqua-light: ${themeObject[theme].chartColorAquaLight};
  --chart-color-cyan-full: ${themeObject[theme].chartColorCyanFull};
  --chart-color-cyan-strong: ${themeObject[theme].chartColorCyanStrong};
  --chart-color-cyan-medium: ${themeObject[theme].chartColorCyanMedium};
  --chart-color-cyan-light: ${themeObject[theme].chartColorCyanLight};
  --chart-color-green-full: ${themeObject[theme].chartColorGreenFull};
  --chart-color-green-strong: ${themeObject[theme].chartColorGreenStrong};
  --chart-color-green-medium: ${themeObject[theme].chartColorGreenMedium};
  --chart-color-green-light: ${themeObject[theme].chartColorGreenLight};
  --chart-color-lavender-full: ${themeObject[theme].chartColorLavenderFull};
  --chart-color-lavender-strong: ${themeObject[theme].chartColorLavenderStrong};
  --chart-color-lavender-medium: ${themeObject[theme].chartColorLavenderMedium};
  --chart-color-lavender-light: ${themeObject[theme].chartColorLavenderLight};
  --chart-color-purple-full: ${themeObject[theme].chartColorPurpleFull};
  --chart-color-purple-strong: ${themeObject[theme].chartColorPurpleStrong};
  --chart-color-purple-medium: ${themeObject[theme].chartColorPurpleMedium};
  --chart-color-purple-light: ${themeObject[theme].chartColorPurpleLight};
  --chart-color-gray: ${themeObject[theme].chartColorGray};
  
  --notification-success-color: ${themeObject[theme].notificationSuccess};
  --notification-primary-color: ${themeObject[theme].notificationPrimary};
  --notification-warning-color: ${themeObject[theme].notificationWarning};
  --notification-error-color: ${themeObject[theme].notificationError};

  --tag-background-color: ${themeObject[theme].tagBackground};

  --icon-color: ${themeObject[theme].icon};
  --icon-hover-color: ${themeObject[theme].iconHover};
  --box-shadow: ${themeObject[theme].boxShadow};
  --box-shadow-hover: ${themeObject[theme].boxShadowHover};

  --heading-color: ${themeObject[theme].heading};
  --item-hover-bg: ${themeObject[theme].itemHoverBg};
  --background-base-color: ${themeObject[theme].backgroundColorBase};
  --border-base-color: ${themeObject[theme].borderBase};
  --disabled-color: ${themeObject[theme].disable};
  --disabled-bg-color: ${themeObject[theme].disabledBg};
  --layout-body-bg-color: ${themeObject[theme].layoutBodyBg};
  --layout-header-bg-color: ${themeObject[theme].layoutHeaderBg};
  --input-placeholder-color: ${themeObject[theme].inputPlaceholder};
  --avatar-bg: ${themeObject[theme].avatarBg};
  --alert-text-color: ${themeObject[theme].alertTextColor};
  --breadcrumb-color: ${themeObject[theme].breadcrumb};
`;

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`;

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
  --ant-success-color-deprecated-bg: ${antThemeObject['dark'].successBg} !important;
  --ant-success-color-deprecated-border: ${antThemeObject['dark'].successBorder} !important;
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;
