import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS, BREAKPOINTS } from '@app/styles/themes/constants';
import styled from 'styled-components';

interface ButtonProps {
  $backgroundColor: string;
  $textColor: string;
}

export const StyledButton = styled(BaseButton)<ButtonProps>`
    width: 15rem;
    background-color: ${props => props.$backgroundColor};
    color: ${props => props.$textColor};
    margin-top: 2rem;
   justify-content: start;
    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
        margin-top: 1.5rem;
    }
`;

export const IconAvatar = styled(BaseAvatar)`
  background-color: ${BASE_COLORS.white};
  margin-right: 0.5rem;
`;
