import React from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './HomeLayout.style';
import { Home } from '@app/components/home/Home';

const HomeLayout: React.FC = () => {
  return (
    <S.Wrapper>
      <Home/>
    </S.Wrapper>
  );
};

export default HomeLayout;
