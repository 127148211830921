import React from 'react';
import { Navigate } from 'react-router-dom';
import { WithChildrenProps } from '@app/types/generalTypes';
import { persistToken, readToken } from '@app/services/localStorage.service';
import { getCookieValue } from '@app/services/cookies.service';
import { storageValues } from '@app/locales/constants';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  let token = readToken() ? readToken() : getCookieValue(storageValues.jwtToken) ? getCookieValue(storageValues.jwtToken) : null;

  if (token) {
    persistToken(token);
  } else {
    // Parse the query parameter to get the JWT token
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = urlParams.get('token');

    if (jwtToken) {
      // Use the JWT token as needed (e.g., store it in localStorage)
      persistToken(jwtToken);
      token = jwtToken;
    }
  }

  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
