import styled from 'styled-components';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseCollapse } from '../common/BaseCollapse/BaseCollapse';
import { BaseCol } from '../common/BaseCol/BaseCol';

export const TextWrapper = styled.div`
    margin-bottom: 1rem;

    @media only screen and ${media.md} {
    margin-bottom: 1.25rem;
    }

    @media only screen and ${media.xl} {
    margin-bottom: 1.5rem;
    }
`;

export const Text = styled(BaseTypography.Text)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--text-main-color);

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    font-size: ${FONT_SIZE.md};
  }
`;

interface TitleColProps{
    $isFromHome: boolean;
}

export const TitleCol = styled(BaseCol)<TitleColProps>`
    padding: ${({ $isFromHome }) => ($isFromHome ? '0 0 1rem 0' : '2.5rem 1rem 0 1rem')}
`;

interface StyledCollapseProps{
    $isFromHome: boolean;
}

export const StyledCollapse = styled(BaseCollapse)<StyledCollapseProps>`
  .ant-collapse-header {
    font-size: ${FONT_SIZE.xl};
    font-weight: ${FONT_WEIGHT.bold};

    color: var(--main-color);
    border-bottom: 1px solid var(--tag-background-color);
    margin: 2rem 0 !important;

    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
        font-size: ${FONT_SIZE.md};
        margin: ${({ $isFromHome }) => ($isFromHome ? '1rem 0' : '1.5rem 0')} !important; 
     }
  }

  .ant-collapse-header-text {
    flex: auto !important;
    white-space: normal;
    word-wrap: break-word;
    text-align: left !important;
  }

  .ant-collapse-content-box {
    text-align: left;
    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
        margin: 0 1rem !important;
        padding: 0.5rem 0 !important;
     }
  }
`;


export const Title = styled(BaseTypography.Text)`
    font-size: 1.6rem;
    font-weight: 800;
    color: var(--text-main-color);
 
    @media only screen and ${media.md} {
      font-size: 1.75rem;
    }

    @media only screen and ${media.xl} {
      font-size: 2.75rem;
    }
`;

export const Span = styled.span`
  color: var(--primary-color);
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;