import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import { normalizeProp } from '@app/utils/utils';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { InfoCircleOutlined } from '@ant-design/icons';

interface CardInternalProps {
  $padding: string | number | [number, number];
  $autoHeight: boolean;
}

export const Card = styled(AntCard) <CardInternalProps>`
  display: flex;
  flex-direction: column;
  background: var(--background-color);

  ${(props) => props.$autoHeight && 'height: 100%'};

  box-shadow: var(--box-shadow);
  border-radius: ${BORDER_RADIUS} !important;

  .ant-card-head {
    border-bottom: 0;

    font-weight: ${FONT_WEIGHT.bold};

    .ant-card-head-title {
      white-space: unset;
      overflow: unset;
      padding-bottom: 0;
      color: var(--chart-legend-label-color);
      font-size: ${FONT_SIZE.xs};
      font-weight: ${FONT_WEIGHT.medium};
    }

    @media only screen and ${media.xl} {
      font-size: ${FONT_SIZE.md};

      .ant-card-head-title {
        font-weight: ${FONT_WEIGHT.medium};
        padding-bottom: 0.25rem;
      }
    }
  }

  .ant-card-body {
    flex-grow: 1;

    padding: ${(props) => props.$padding && normalizeProp(props.$padding)};
  }

  .ant-card-bordered {
    border-color: #f0f0f0;
  }
`;

export const InfoIcon = styled(InfoCircleOutlined)`
color: var(--chart-legend-label-color);
    font-size: ${FONT_SIZE.xs};
`;

export const HeaderWrapper = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
`;
