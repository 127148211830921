import styled, { css } from 'styled-components';
import { BORDER_RADIUS, BREAKPOINTS, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BASE_COLORS } from '@app/styles/themes/constants';

interface OnboardingColProps {
  $index: number;
}

export const OnboardingCol = styled(BaseCol)<OnboardingColProps>`
    padding: 1rem 1rem 2rem 1rem !important;
    border-radius: ${BORDER_RADIUS};
    background: var(--secondary-color);

    margin-bottom: 1rem;

    @media only screen and ${media.lg} {
      padding: 1.2rem 1.2rem 1.8rem 1.2rem !important;
      border: none; 
    }

    @media only screen and ${media.xxxl} {
      padding: 1.2rem 4rem !important;
    }

    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }

    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
      ${({ $index }) =>
      $index === 2 &&
      css`
          margin-bottom: 0;
      `}
    }
`;

export const OnboardingRow = styled(BaseRow)`
  padding: 2.5rem 0 2.5rem 0;  
`;

export const Imagerapper = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.7rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1rem;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.7rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1rem;
  }
`;


export const Title = styled(BaseTypography.Text)`
  font-weight: 800;
  color: ${BASE_COLORS.white};
  font-size: 1.3rem;

  @media only screen and ${media.xl} {
    font-size: 1.4rem;
  }
`;

export const OnboardTitleWrapper = styled.div`
  margin-top: 2rem;  

  @media only screen and ${media.md} {
    margin-top: 2.5rem;
  }

  @media only screen and ${media.xl} {
    margin-top: 3rem;
  }
`;

export const OnboardTitle = styled(Title)`
    font-size: 1.6rem;
    font-weight: 800;
    color: var(--text-main-color);

    @media only screen and ${media.md} {
      font-size: 1.75rem;
    }

    @media only screen and ${media.xl} {
      font-size: 2.75rem;
    }
`;

export const TextWrapper = styled.div`
    margin-bottom: 1rem;

    @media only screen and ${media.md} {
    margin-bottom: 1.25rem;
    }

    @media only screen and ${media.xl} {
    margin-bottom: 1.5rem;
    }
`;

export const Text = styled(Title)`
  font-size: 0.95rem;
  font-weight: 300;
  color: ${BASE_COLORS.white};

  @media only screen and ${media.xl} {
    font-size: 0.95rem;
  }
`;

export const Span = styled.span`
  font-weight: 800;
  color: var(--primary-color);
`;