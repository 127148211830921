import influencerImg from "@app/assets/images/influencers-image.svg";
import engGrowthImg from "@app/assets/images/eng-growth-image.svg";
import saveCostImg from "@app/assets/images/saving-cost-image.svg";
import { ArrowUpOutlined } from "@ant-design/icons";

interface Highlights {
  id: number;
  title: string;
  img: string;
  description: string;
}

export const highlights : Highlights[] = [
  {
    id: 1,
    img: influencerImg, 
    title: 'Collaborate with \n 5000+ Influencers',
    description: ' Start connecting with influencers, brands, and followers to expand your network and unlock new opportunities for growth ',
  },
  {
    id: 2,
    img: engGrowthImg,
    title: '↑12% Audience Reach  \n via Collabs',
    description: ' Leverage each other\'s audiences to boost visibility and attract new followers interested in both influencers\' content',
  },
  {
    id: 3,
    img: saveCostImg,
    title: '100% Earnings \n No Commissions',
    description: " Unlike agencies, we don't take a cut from your brand deals, ensuring you keep 100% of your earnings "
  }
];