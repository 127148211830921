import React from 'react';
import * as S from './Footer.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { companyContactEmail, companyInstagramLink, labels } from '@app/locales/constants';
import BrandLogo from '@app/assets/lyncsy-logo-transparent.png';
import { InstagramOutlined, FacebookOutlined, LinkedinOutlined, XOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { WithChildrenProps } from '@app/types/generalTypes';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface FooterProps extends WithChildrenProps {
  isFromHome?: boolean;
}

export const Footer: React.FC<FooterProps>= ({ isFromHome=false }) => {
  const user = useAppSelector((state) => state.influencer.influencer);
  const { mobileOnly } = useResponsive();
  const year = new Date().getFullYear();

  const openEmail = () => {
    window.open(`mailto:${companyContactEmail}`);
  };

  return (
    <>
      <S.Space />
      <S.StyledFooter id="footer" $isFromHome={isFromHome}>
        <S.FooterWrapper>
          <BaseRow>
            <S.FooterCol lg={6} sm={6} xs={12}>
              <S.FooterCenter>
                <S.FooterTitle $isFromHome={isFromHome}>{labels.quickLinks}</S.FooterTitle>
                <div>
                  <S.StyledLink href={`/${process.env.REACT_APP_DEMO_USER}`} target="_blank" rel="noopener noreferrer" $isFromHome={isFromHome}>
                    {labels.demo}
                  </S.StyledLink>
                </div>
                <div>
                  <S.StyledLink href={'/auth/sign-up'} $isFromHome={isFromHome}>{labels.signUp}</S.StyledLink>
                </div>
                <div>
                  <S.StyledLink onClick={openEmail} $isFromHome={isFromHome}>{labels.contact}</S.StyledLink>
                </div>
              </S.FooterCenter>
            </S.FooterCol>
            <S.FooterCol lg={6} sm={6} xs={12}>
              <S.FooterCenter>
                <S.FooterTitle $isFromHome={isFromHome}>{labels.support}</S.FooterTitle>
                <div>
                  {user 
                    ? <S.StyledLink href={`${process.env.REACT_APP_STRIPE_DONATION_LINK}`} target="_blank" rel="noopener noreferrer" $isFromHome={isFromHome}>{labels.donation}</S.StyledLink> 
                    : <S.StyledLink href="#" $isFromHome={isFromHome}>{labels.feedback}</S.StyledLink>
                  }
                </div>
                <div>
                  <S.StyledLink href="#" $isFromHome={isFromHome}>{labels.mention}</S.StyledLink>
                </div>
              </S.FooterCenter>
            </S.FooterCol>
            <S.FooterCol lg={6} sm={6} xs={12}>
              <S.FooterCenter>
                <S.FooterTitle $isFromHome={isFromHome}>{labels.about}</S.FooterTitle>
                <div>
                  <S.StyledLink href="/faq" target="_blank" rel="noopener noreferrer" $isFromHome={isFromHome}>
                    {labels.FAQ}
                  </S.StyledLink>
                </div>
                <div>
                  <S.StyledLink target="_blank" rel="noopener noreferrer" href="#" $isFromHome={isFromHome}>
                    {labels.careers}
                  </S.StyledLink>
                </div>
              </S.FooterCenter>
            </S.FooterCol>
            <S.FooterCol lg={6} sm={6} xs={12}>
              <S.FooterCenter>
                <S.FooterTitle $isFromHome={isFromHome}>
                  {labels.legal}
                </S.FooterTitle>
                <div>
                  <S.StyledLink href={'/legal/privacy-policy'} target="_blank" rel="noopener noreferrer" $isFromHome={isFromHome}>{labels.privacyPolicy}</S.StyledLink>
                </div>
                <div>
                  <S.StyledLink href={'/legal/terms-of-service'} target="_blank" rel="noopener noreferrer" $isFromHome={isFromHome}>{labels.termsOfService}</S.StyledLink>
                </div>
              </S.FooterCenter>
            </S.FooterCol>
          </BaseRow>
        </S.FooterWrapper>
        <S.BottomBar justify="center" align="middle" gutter={[{ xs: 0, sm: 0 }, { xs: 10, sm: 0 }]}>
          {mobileOnly ? (
            <>
              <a href={'/'} target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '1rem' }}>
                <img src={BrandLogo} style={{ maxWidth: '100%', maxHeight: '2.3rem' }} />
              </a>
              <S.StyledLink href={'/'} $isFromHome={isFromHome}>
                &copy; {labels.brandOfficialName} {year}.
              </S.StyledLink>
            </>
          ) : (
            <S.BrandWrapper>
              <a href={'/'} target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={BrandLogo} style={{ maxWidth: '100%', maxHeight: '2.3rem', marginRight: '2.5rem' }} />
                <S.StyledLink href={'/'} $isFromHome={isFromHome}>
                  &copy; {labels.brandOfficialName} {year}.
                </S.StyledLink>
              </a>
            </S.BrandWrapper>
          )}

          <S.IconsWrapper $isFromHome={isFromHome}>
            <a href={companyInstagramLink} target="_blank" rel="noreferrer">
              <InstagramOutlined />
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <FacebookOutlined />
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <LinkedinOutlined />
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              <XOutlined />
            </a>
          </S.IconsWrapper>
        </S.BottomBar>
      </S.StyledFooter>
    </>
  );
};

