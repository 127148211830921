import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './HeaderLogo.style';
import BrandLogo from '@app/assets/lyncsy-logo-transparent.png';
import Logo from '@app/assets/logo-transparent.png';

interface HeaderLogoProps {
  isFromHome?: boolean;
}

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ isFromHome = false }) => {
  const { mobileOnly } = useResponsive();

  return (
    (isFromHome || !mobileOnly) ? (
      <a href={'/'} target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={BrandLogo} style={{ maxWidth: '100%', maxHeight: '2.5rem' }} />
      </a>
    ) : (
      <a href={'/'} target="_blank" rel="noreferrer">
        <S.LogoAvatar src={Logo} alt="Lyncsy" shape='square' size={35} />
      </a>
    )
  );
};
