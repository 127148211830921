import styled from 'styled-components';
import { media, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export const BaseFormTitle = styled(BaseTypography.Text)`
  font-weight: ${FONT_WEIGHT.semibold}; 
  font-size: ${FONT_SIZE.xs};
  color:var(--text-main-color); 
  display: block;

  @media only screen and ${media.xxxl} {
    font-size:  ${FONT_SIZE.md};
  }
`;
