import styled from 'styled-components';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BREAKPOINTS, media } from '@app/styles/themes/constants';

export const BannerCol = styled(BaseCol)`

`;


export const FeatureCol = styled(BaseCol)` 

`;

export const FAQCol = styled(BaseCol)` 
   padding: 0 1.5rem 7.5rem 1.5rem;
   @media only screen and (max-width: ${BREAKPOINTS.lm - 0.02}px) {
      padding: 0 0.5rem 1.5rem 0.5rem;
   }
`;

export const ContentRow = styled(BaseRow)`
   text-align: center;

   ${FeatureCol} {
      text-align: left !important;
      @media only screen and (max-width: ${BREAKPOINTS.lm - 0.02}px) {
         padding: 0 1.5rem;
      }
   }

   ${BannerCol} {
      text-align: left !important;
      @media only screen and (max-width: ${BREAKPOINTS.lm - 0.02}px) {
         padding: 0 1.5rem;
     }
   }
`;
