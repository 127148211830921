
import { httpApi } from '@app/api/http.api';
import { ContractModel, ContractUpdateModel } from '@app/domain/ContractModel';

export interface UpdateCollabResponse {
    statusMessage: string;
}

export const upsertContract = async (request: ContractUpdateModel): Promise<UpdateCollabResponse> => {
    const response = await httpApi.post<UpdateCollabResponse>('contracts/update-contract', request);
    return response.data;
};

export const getContracts = async (): Promise<ContractModel[]> => {
    const response = await httpApi.get<ContractModel[]>('contracts/get-contracts');
    return response.data;
};

