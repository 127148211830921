import styled from 'styled-components';
import bg from "@app/assets/images/login-bg-old.webp";
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media} from '@app/styles/themes/constants';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import Typography from 'antd/es/typography/Typography';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${bg});
  background-size: cover;
`;

export const FormCard = styled(BaseCard)`
`;

export const ContentCol = styled(BaseCol)`
  padding: 1rem 2rem;
  max-width: 31rem; 
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and ${media.xs} {
    padding: 0 1rem;
  }

`;

export const Title = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  margin-bottom: 1.5rem;
  text-align: center;

  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xl};
    line-height: 1.125rem;
  }

  @media only screen and ${media.md} {
    font-size: 1.75rem;
    line-height: 1.75rem;
  }

  @media only screen and ${media.xl} {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
`;

export const Subtitle = styled(Typography)`
    color: var(--text-main-color);
  text-align: center;
  font-weight: ${FONT_WEIGHT.regular};
  
  @media only screen and ${media.xs} {
    font-size: ${FONT_SIZE.xs};
    margin-bottom: 1.25rem;
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
    margin-bottom: 1.5rem;
   }

  @media only screen and ${media.lg} {
    font-size: ${FONT_SIZE.md};
    margin-bottom: 2rem;
  }
`;

export const FormTitle = styled.span`
  color: var(--text-light-color);
  text-align: center;
  font-weight: ${FONT_WEIGHT.medium};

  @media only screen and ${media.xs} {
    margin-bottom: 0.625rem;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.875rem;
    font-size: ${FONT_SIZE.md};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 0.9375rem;
    font-size: ${FONT_SIZE.md};
    line-height: 2.125rem;
  }
`;

export const BtnGroupRow = styled(BaseRow)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    margin-bottom: 1.5rem;
}
`;

export const Text = styled(Typography)`
  color: var(--text-light-color);
  text-align: center;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    font-size: ${FONT_SIZE.xxs};
  }
`;

export const LinkText = styled.span`
  text-decoration: underline;
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    font-size: ${FONT_SIZE.xxs};
  }
`;


