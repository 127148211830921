import styled, { css } from 'styled-components';
import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

interface TextProps {
  $isSmallerScreen: boolean;
}

export const TextCol = styled(BaseCol)<TextProps>`
  ${props =>
    !props.$isSmallerScreen &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `}
`;

interface ImgColProps {
  $isThirdImg?: boolean;
}

export const ImgCol = styled(BaseCol)<ImgColProps>`
  transition: transform 0.3s ease !important;
  margin-bottom: ${props => (props.$isThirdImg ? '2rem' : '0')};
  &:hover {
    transform: translateY(-5px);
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.7rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1rem;
  }
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 1.6rem;
  font-weight: 800;
  text-align: left;
  color: var(--text-main-color); 

  @media only screen and ${media.md} {
    font-size: 1.6rem;
  }

  @media only screen and ${media.xl} {
    font-size: 2.5rem;
  }
`;

export const FeatureTitleWrapper = styled.div`
  margin-top: 1.75rem;  
  margin-bottom: 0.7rem;

  @media only screen and ${media.md} {
    margin-top: 2.25rem;
    margin-bottom: 0.9rem;
  }

  @media only screen and ${media.xl} {
    margin-top: 2.75rem;
    margin-bottom: 1.1rem;
  }
`;

export const FeatureTitle = styled(Title)`
  color: var(--text-main-color);
  font-size: 1.4rem;
    
    @media only screen and ${media.md} {
      font-size: 1.75rem;
    }

    @media only screen and ${media.xl} {
      font-size: 2.75rem;
    }
`;


export const TextWrapper = styled.div`
    margin-bottom: 1rem;

    @media only screen and ${media.md} {
    margin-bottom: 1.25rem;
    }

    @media only screen and ${media.xl} {
    margin-bottom: 1.5rem;
    }
`;

export const Text = styled(Title)`
  font-size: 0.95rem; 
  font-weight: 300;
  text-align: left;
  color: var(--text-light-color);

  @media only screen and ${media.xl} {
    font-size: 1.25rem;
  }
`;

export const Span = styled.span`
  font-weight: 600;
  color: var(--primary-color);
`;

export const BtnWrapper = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.lm - 0.02}px) {
    display: flex;
    justify-content: center !important;
  }
`;

export const FeatureBtn= styled(BaseButton)`
  font-weight: 800;
`;

interface ImageProps {
    $img: string;
    $position: 'left' | 'right' | 'bottom';
    $index: string;
}

export const Image = styled.div<ImageProps>`
  width: 100%;

  @media only screen and ${media.xs} {
    background: url(${props => props.$img}) no-repeat top / 21.875rem !important;
    height: ${props => (props.$index === '2' ? '16.875rem' : '23.875rem')} !important;
  }

  @media only screen and ${media.sm} {
    background: url(${props => props.$img}) no-repeat bottom / 31.875rem !important;
    height: 31.875rem !important;
    height: ${props => (props.$index === '2' ? '29.3rem' : '31.875rem')} !important;
  }

  @media only screen and ${media.xl} {
    background: url(${props => props.$img}) no-repeat ${props => (props.$position === 'left' ? 'left' : 'right')} / 37.75rem !important;
    height: ${props => (props.$index === '2' ? '38.625rem' : '37.625rem')} !important;
  }
`;


  


