import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import * as S from './MainHeader.styles';

interface MainHeaderProps extends WithChildrenProps {
  isTwoColumnsLayout: boolean;
  isTransparent: boolean;
  isFromHome: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ isTwoColumnsLayout, isTransparent, isFromHome, children }) => {
  return (
    <S.Header
      $isTwoColumnsLayoutHeader={isTwoColumnsLayout}
      $isTransparent={isTransparent}
      $isFromHome={isFromHome}
    >
      {children}
    </S.Header>
  );
};


