import React from 'react';
import * as S from './Onboarding.styles'
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { onboardingSteps } from '@app/constants/config/onboardingSteps';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { useResponsive } from '@app/hooks/useResponsive';

export const Onboarding: React.FC = () => {
    const { mobileOnly, mobileAndTabletOnly, isDesktopSmall, isDesktopMedium } = useResponsive();
    const isSmallerScreen = (mobileAndTabletOnly || isDesktopSmall && !isDesktopMedium);

    const onboardingCols = onboardingSteps.map((card, i) => (
        <S.OnboardingCol key={i.toString()} $index={i} md={24} lg={7}>
            <S.Imagerapper>
                <BaseImage src={card.img} alt="" width={150} height={150} preview={false} />
            </S.Imagerapper>
            <S.TitleWrapper key={`title${i}`}>
                <S.Title>{card.title}</S.Title>
            </S.TitleWrapper>
            <S.TextWrapper key={`description${i}`}>
                <S.Text>{card.description}</S.Text>
            </S.TextWrapper>
        </S.OnboardingCol>
    ));

    return (
        <ScrollOverPack style={{
            width: '100%',
            margin: 'auto',
            overflow: 'hidden',
            minHeight: isSmallerScreen ? '50rem': '40rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: isSmallerScreen ? "0 1.5rem" : "2.5rem 0",
        }}>
            <section>
                <QueueAnim
                    component='Row'
                    type="bottom"
                    leaveReverse
                    duration={200}
                    delay={300}
                >
                    <S.OnboardTitleWrapper key="onboardtitle">
                        <S.OnboardTitle key="onboardtitlecontent">Get started in just
                            {mobileOnly && <br />}
                            <S.Span> 3 minutes!</S.Span>
                        </S.OnboardTitle>
                    </S.OnboardTitleWrapper>
                </QueueAnim>
                <QueueAnim
                    component='Row'
                    type="bottom"
                    leaveReverse
                    duration={200}
                    delay={450}
                >
                    <S.OnboardingRow key="row" justify="space-between">
                        {onboardingCols}
                    </S.OnboardingRow>
                </QueueAnim>
            </section>
        </ScrollOverPack>
    );
};
