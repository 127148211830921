import { storageValues } from '@app/locales/constants'; 

export const persistToken = (token: string): void => {
  localStorage.setItem(storageValues.accessToken, token);
};

export const readToken = (): string | null => {
  return localStorage.getItem(storageValues.accessToken);
};

export const deleteToken = (): void => localStorage.removeItem(storageValues.accessToken);

//--------------------------------------------------MOCK DATA----------------------------------------------------------//
// const testUser = {
//   id: 1,
//   firstName: 'Chris',
//   lastName: 'Johnson',
//   jobTitle: 'Creative Content Creator',
//   pronouns: ['He/Him', 'They/Them'],
//   imgUrl: avatarImg,
//   userName: '@john1989',
//   email: {
//     name: 'chris.johnson@altence.com',
//     verified: true,
//   },
//   primaryPhoneNumber: {
//     number: '+18143519459',
//     verified: false,
//   },
//   age: 25,
//   lang: 'en',
//   country: 'CA',
//   province: 'Ontario',
//   city: 'Toronto',
//   primaryAddress: '14 King Street',
//   zipCode: 5211,
//   about: '25 year old content creator looking for new oppportunites to collaborate with different brands. I have speciality in interior design and have passion for good design. Would love to promote any good designs',
//   demographicReach: ['Male', 'Young Adults', 'Canada', 'USA', 'English'],
//   influencerCategories: ['Design', 'Interior Design', 'Art', 'Creativity', 'Painting' ],
//   totalFollowers: 300000,
//   website: 'altence.com',
//   socialsLinks: {
//     twitter: '@altence_team',
//     facebook: 'https://facebook.com/groups/1076577369582221',
//     linkedin: 'https://linkedin.com/company/altence',
//   },
//   socialMediaAccounts:[
//   {
//     socialMediaType: socialMediaTypes.YouTube,
//     primaryAccount: false,
//     disabledAccount: false,
//     accountFilter: null,
//     //accountId?: ,
//     //accountLink?: ,
//     accountUsername: 'channel2',
//     accountEmail: 'channel2@gmail.com',
//     totalFollowers: 0,
//     accountCategories: [],
//     accountDemographics: [],
//     pinnedPostLinks: [
//       "https://www.youtube.com/",
//       "https://www.youtube.com/"
//     ],
//   },
//   {
//     socialMediaType: socialMediaTypes.Instagram,
//     primaryAccount: false,
//     disabledAccount: false,
//     accountFilter: null,
//     //accountId?: ,
//     //accountLink?: ,
//     accountUsername: 'intax2',
//     accountEmail: 'intax2@gmail.com',
//     totalFollowers: 0,
//     accountCategories: [],
//     accountDemographics: [],
//     pinnedPostLinks: [
//       "https://www.instagram.com/",
//       "https://www.instagram.com/",
//   ],
//   },
// ],
// experience: [
//   {
//   jobTitle: "Content Designer",
//   employmentType: "Permanent Full-Time",
//   companyName: "CIBC",
//   companyLogo: avatarImg,
//   city: "Toronto",
//   province: "Ontario",
//   country: "Canada",
//   locationType: "Hybrid",
//   currentRole: false,
//   //location: "Toronto, Ontario, Canada",
//   startDate: new Date(2022, 7, 21),
//   endDate: new Date(2023, 11, 10),
//   industry: "Bank",
//   description: "Worked in a agile team where I performed Writing, editing, blogging, and updating content for everything from brochures and marketing and promotional materials to emails, websites, blogs, and more.",
//   skills: ["Design", "Figma", "Account Management"],
//   mediaContents: [
//     "https://www.instagram.com/",
//     "https://www.instagram.com/",
//   ]
// },
// {
//   jobTitle: "Content Creater",
//   employmentType: "Permanent Full-Time",
//   companyName: "CIBC",
//   companyLogo: avatarImg,
//   city: "Toronto",
//   province: "Ontario",
//   country: "Canada",
//   //location: "Toronto, Ontario, Canada",
//   locationType: "Hybrid",
//   currentRole: true,
//   startDate: new Date(2023, 11, 21),
//   endDate: null,
//   industry: "Bank",
//   description: "Worked in a agile team where I performed Writing, editing, blogging, and updating content for everything from brochures and marketing and promotional materials to emails, websites, blogs, and more.",
//   skills: ["Design", "Figma", "Account Management"]
// },
// ]
// };
