import { httpApi } from '@app/api/http.api';
import { Collab, Experience, InfluencerModel, PersonalInfo, SocialMediaAccount } from '@app/domain/InfluencerModel';
import { AnalyticsModel, PostMetricsModel } from '@app/domain/AnalyticsModel';
import { FileUpload, SocialMediaType } from '@app/interfaces/interfaces';

export interface UpdatePostVisibilityRequest {
    analyticsId: string,
    postId: string,
    isVisible: boolean,
    socialMediaType: SocialMediaType
}

export interface GetPostMetricThumbnailsRequest {
    allPostMetrics: PostMetricsModel;
    influencerId: string;
}

export interface GetInfluencerByCustomUrlResponse {
    profile: InfluencerModel,
    analytics: AnalyticsModel;
}

export interface GetInfluencerResponse {
    influencer: InfluencerModel,
    postMetrics: PostMetricsModel;
}

export interface UpdateImageResponse {
    imageUrl: string,
    influencer: InfluencerModel
}


export const getInfluencer = async (): Promise<GetInfluencerResponse> => {
    const response = await httpApi.get<GetInfluencerResponse>('influencers/get-influencer');
    return response.data;
};

export const getInfluencerByCustomUrl = async (request: string): Promise<GetInfluencerByCustomUrlResponse> => {
    const response = await httpApi.post<GetInfluencerByCustomUrlResponse>('influencers/get-influencer/custom-url', { customUrl: request });
    return response.data;
};

export const getPostMetricThumbnails = async (request: GetPostMetricThumbnailsRequest): Promise<PostMetricsModel> => {
    const response = await httpApi.post<PostMetricsModel>('influencers/get-post-thumbnails', request);
    return response.data;
};

export const updateInfluencer = async (request: PersonalInfo): Promise<InfluencerModel> => {
    const response = await httpApi.post<InfluencerModel>('influencers/update-influencer', request);
    return response.data;
};

export const updateSocialMediaAccount = async (request: SocialMediaAccount): Promise<InfluencerModel> => {
    const response = await httpApi.post<InfluencerModel>('influencers/update-sm-account', request);
    return response.data;
};

export const updateExperience = async (request: Experience): Promise<InfluencerModel> => {
    const response = await httpApi.post<InfluencerModel>('influencers/update-experience', request);
    return response.data;
};

export const updateCollab = async (request: Collab): Promise<InfluencerModel> => {
    const response = await httpApi.post<InfluencerModel>('influencers/update-collab', request);
    return response.data;
};

export const deleteCollab = async (request: string): Promise<InfluencerModel> => {
    const response = await httpApi.post<InfluencerModel>('influencers/delete-collab', { databaseId: request });
    return response.data;
};

export const updateProfileImage = async (request: FileUpload): Promise<UpdateImageResponse> => {
    const response = await httpApi.post<UpdateImageResponse>('influencers/update-profile-image', request);
    return response.data;
};

export const updatePostVisibility = async (request: UpdatePostVisibilityRequest): Promise<PostMetricsModel> => {
    const response = await httpApi.post<PostMetricsModel>('influencers/update-post-visibility', request);
    return response.data;
};

export const deleteInfluencer = async () => {
    const response = await httpApi.post('influencers/delete-influencer');
    console.log('deleteInfluencer api', response.data);
    return response.data;
};