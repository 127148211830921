import React from 'react';
import * as S from './Feature.styles'
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface FeatureProps {
  isImgFirst: boolean;
  img: string;
  title: string;
  subTitle: string;
  btnText: string;
  index: string;
}

export const Feature: React.FC<FeatureProps> = ({ isImgFirst, img, title, subTitle, btnText, index }) => {
  const { mobileAndTabletOnly, isDesktopSmall, isDesktopMedium } = useResponsive();
  const isSmallerScreen = (mobileAndTabletOnly || isDesktopSmall && !isDesktopMedium);
  const navigate = useNavigate();

  const redirectToDemo = () => {
    navigate(`/${process.env.REACT_APP_DEMO_USER}`);
  };

  const imgPosition = isImgFirst ? 'left' : 'right';
  const imgContent = (
    <S.ImgCol key={`col${index}`} sm={24} lg={24} xl={16} $isThirdImg={mobileAndTabletOnly && index === '2'}>
      <QueueAnim
        key={`image${index}`}
        duration={450}
        delay={100}
        type={imgPosition}
      >
        <S.Image key="img" $index={index} $img={img} $position={isSmallerScreen ? 'bottom' : imgPosition} />
      </QueueAnim>
    </S.ImgCol>
  );

  const textDelay = isSmallerScreen ? 500 : 400; //  img comes first before text
  const featureContent = (
    <S.TextCol key={`col${index}`} sm={24} lg={24} xl={8} $isSmallerScreen={isSmallerScreen}>
      <BaseRow align='middle' justify='start'>
        <QueueAnim key={`text${index}`} duration={450} delay={textDelay} type="bottom">
          <S.TitleWrapper key={`title${index}`}>
            <S.Title>{title}</S.Title>
          </S.TitleWrapper>
        </QueueAnim>
        <QueueAnim key={`text${index}`} duration={450} delay={textDelay} type="bottom">
          <S.TextWrapper key={`subtitle${index}`}>
            <S.Text>{subTitle}</S.Text>
          </S.TextWrapper>
        </QueueAnim>
          <QueueAnim key={`text${index}`} duration={450} delay={textDelay} type="bottom">
            <S.BtnWrapper key={`button${index}`}>
              {isImgFirst ? (
                <S.FeatureBtn type="primary" size="large" icon={<LeftOutlined />} onClick={redirectToDemo}>
                  {btnText}
                </S.FeatureBtn>
              ) : (
                <S.FeatureBtn type="primary" size="large" onClick={redirectToDemo}>
                  {btnText}
                  <RightOutlined />
                </S.FeatureBtn>
              )}
            </S.BtnWrapper>
          </QueueAnim>
      </BaseRow>
    </S.TextCol>
  );

  const paddingBottom = isSmallerScreen ? '2.5rem' : '5rem';

  return (
    <ScrollOverPack
      component="section"
      key={`scroll${index}`}
      style={{
        width: '100%',
        margin: 'auto',
        overflow: 'hidden',
        minHeight: '20rem',
        paddingBottom: `${index === '1' || index === '2' ? paddingBottom : '2.5rem'}`,
        display: 'flex',
        flexDirection: isSmallerScreen ? 'column-reverse' : 'row',
      }}>
      {isImgFirst ? (
        <>
          {imgContent}
          {featureContent}
        </>
      ) : (
        <>
          {featureContent}
          {imgContent}
        </>
      )}
    </ScrollOverPack>
  );
};




