import React from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import * as S from './Banner.styles'
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { SelectOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { labels } from '@app/locales/constants';
const AppVideoSrc = process.env.REACT_APP_ASSETS_BUCKET_VIDEO + '/demo/home/AppDemoVideo';
import { useResponsive } from '@app/hooks/useResponsive';
import { Highlight } from '../highlight/Highlight';
export const Banner: React.FC = () => {
    const { mobileAndTabletOnly, isDesktopSmall, isDesktopMedium, mobileOnly, desktopOnly } = useResponsive();
    const isSmallerScreen = (mobileAndTabletOnly || isDesktopSmall && !isDesktopMedium);

    const navigate = useNavigate();

    const redirectToSignUp = () => {
        navigate('/auth/sign-up');
    };

    const redirectToDemo = () => {
        navigate(`/${process.env.REACT_APP_DEMO_USER}`);
    };

    return (
        <>
            <ScrollOverPack
                component="section"
                key={`scroll`}
                style={{
                    width: '100%',
                    margin: 'auto',
                    overflow: 'hidden',
                    minHeight: '20rem',
                    paddingTop: '2.5rem',
                    display: 'flex',
                    flexDirection: isSmallerScreen ? 'column' : 'row',
                    alignItems: isSmallerScreen ? '' : 'center',
                    padding: isSmallerScreen ? "1rem 0 1rem" : "2.5rem 0rem",
                }}>
                <BaseCol sm={24} lg={24} xl={13}>
                    <QueueAnim
                        key='text'
                        duration={850}
                        leaveReverse
                        type="left"
                    >
                        <S.TitleWrapper key='title'>
                            <S.Title>
                                {!mobileOnly ? (
                                    <>
                                    <S.TitleSpan>Grow and get paid <br /></S.TitleSpan>through creator <br /> collaborations
                                    {/* <S.TitleSpan>Earn money & grow <br />your reach with</S.TitleSpan>  <br />creator collaboration */}
                                    {/* <S.TitleSpan>Start earning by </S.TitleSpan><br />collaborating with creators */}
                                    </>
                                ) : (
                                    <>
                                        {/* <S.TitleSpan>
                                        Start earning by
                                        </S.TitleSpan>
                                        <br />
                                        collaborating with creators */}
                                        
                                        Grow and get paid through<br /> <S.TitleSpan> Creator Collabs</S.TitleSpan>
                                    </>
                                )}
                            </S.Title>
                        </S.TitleWrapper>
                        <S.TextWrapper key="subtitle">
                            <S.Text>
                                {mobileOnly ? (
                                    <>
                                    Use Lyncsy to grow your influence by collaborating with bigger influencers or earn by supporting smaller creators
                                         {/* As a creator, your goal is to grow and monetize your influence. With Lyncsy, you can expand your audience by collaborating with larger influencers or earn by assisting smaller ones */}
                                         {/* Start earning and grow your reach by collaborating with creators and brands. Avoid agency fees and keep 100% of your earnings */}
                                         {/* Upgrade from static, boring profile links to a dynamic page with all your content and analytics in one place. Increase your content and brand engagement now with Lyncsy! */}
                                    </>
                                ) : (
                                    <>
                                        <S.CheckIcon /> <S.Span>Monetize your influence</S.Span> and get paid to collaborate<br />
                                        <S.CheckIcon /> <S.Span>Grow your reach</S.Span> by collaborating with other creators<br />
                                        <S.CheckIcon /> <S.Span>Avoid agency fees</S.Span> and keep 100% of your earnings<br />
                                        {/* <S.CheckIcon /> <S.Span>Save Cost</S.Span> on portfolio website & agent fees<br /> */}
                                    </>
                                )}
                            </S.Text>
                        </S.TextWrapper>
                        <S.BtnWrapper key='btn-sign-up' align={"middle"} gutter={[0, 10]}>
                            <BaseCol>
                                <S.SignUpBtn type="primary" size="large" icon={<SelectOutlined />} onClick={redirectToSignUp}>
                                    {labels.getStarted}
                                </S.SignUpBtn>
                            </BaseCol>
                            <BaseCol>
                                <S.DemoBtn
                                    onClick={redirectToDemo}
                                    size="large"
                                >
                                    {labels.previewDemo}
                                </S.DemoBtn>
                            </BaseCol>
                        </S.BtnWrapper>
                    </QueueAnim>
                </BaseCol>
                <BaseCol sm={24} lg={24} xl={11}>
                    {/* <QueueAnim
                        key='video'
                        duration={850}
                        type="right"
                    > */}
                        <S.Video
                            width="100%"
                            autoPlay
                            muted
                           // loop
                            playsInline
                        >
                            <source src={AppVideoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </S.Video>
                    {/* </QueueAnim> */}
                </BaseCol>
            </ScrollOverPack>
            <Highlight />
            {/* {mobileOnly ? (
                <>
                    <Demo />
                    <Highlight />
                </>
            ) : (
                <>
                    <Highlight />
                    <Demo />
                </>
            )} */}
        </>
    );
};