
import { httpApi } from '@app/api/http.api';
import { ApplicationModel, ApplicationUpsertModel } from '@app/domain/ApplicationModel';

export interface UpsertApplicationResponse {
    statusMessage: string;
}

export const upsertApplication = async (request: ApplicationUpsertModel): Promise<UpsertApplicationResponse> => {
    const response = await httpApi.post<UpsertApplicationResponse>('applications/upsert-application', request);
    return response.data;
};

export const getApplications = async (): Promise<ApplicationModel[]> => {
    const response = await httpApi.get<ApplicationModel[]>('applications/get-applications');
    return response.data;
};
