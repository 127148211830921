import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PageDescriptions, PageTitles } from '@app/locales/constants';
import { Home } from '@app/components/home/Home';

const HomePage: React.FC = () => {

  return (
    <>
      <PageTitle description={PageDescriptions.Home}>{PageTitles.Home}</PageTitle>
      <Home />
    </>
  );
};

export default HomePage;
