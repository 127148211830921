import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { BASE_COLORS, BREAKPOINTS, FONT_WEIGHT } from '@app/styles/themes/constants';
import { Menu } from 'antd';
import styled from 'styled-components';

export const HeaderMenu = styled(Menu)`
    background: transparent;
    border-bottom: none !important;
    color: var(--text-light-color);

  @media only screen and (max-width: ${BREAKPOINTS.ml - 0.02}px) {
      color: var(--text-light-color);
   }

    .ant-menu-item:hover {
      color: var(--secondary-color) !important;
    }
  
    .ant-menu-item-selected {
      color: var(--secondary-color) !important; 
    }

    .ant-menu-item-selected::after {
      border-bottom-color: var(--secondary-color) !important;
    }

    .ant-menu-item:hover::after {
      border-bottom-color: var(--secondary-color) !important;
    }
`;

export const MenuTitleSpan = styled.span`
  color: var(--secondary-color) !important; 

  @media only screen and (max-width: ${BREAKPOINTS.ml - 0.02}px) {
    color: var(--text-light-color) !important;
  }

  &.ant-menu-item-selected {
    color: var(--secondary-color) !important; 
  }
`;

export const MobileSignUpCol = styled(BaseCol)`
   margin-top: 0.5rem;
   margin-right: 0.5rem;
`;

export const MobileBugerMenuCol = styled(BaseCol)`
   margin-top: 0.5rem;
`;

export const SignUpCol = styled(BaseCol)`
   display: flex;
   align-items: center;
`;

export const LinkText = styled.span`
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
`;

export const BurgerCol = styled(BaseCol)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);
`;