import React, { useEffect, useState } from 'react';
import { BaseTestimonial } from '@app/components/common/BaseTestimonial/BaseTestimonial';
import { getFeedbacks, Post } from '@app/api/feedback.api';
import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';
import * as S from './Testimonials.styles';
import { useResponsive } from '@app/hooks/useResponsive';
import { Carousel } from 'antd';

export const Testimonials: React.FC = () => {
    const { mobileOnly } = useResponsive();
    const [testimonials, setTestimonials] = useState<Post[]>([]);

    const getFeedbacksFromApi = async () => {
        return await getFeedbacks();
    };

    useEffect(() => {
        const fetchFeedbacks = async () => {
            const feedbacks = await getFeedbacksFromApi();
            setTestimonials(feedbacks.slice().sort((a, b) => a.id - b.id));
        };
        fetchFeedbacks();
    }, []);

    const renderTestimonials = () => {
        return testimonials.map((post) => (
            <BaseTestimonial
                key={post.id}
                handleName={post.handleName}
                rating={post.rating}
                description={post.text}
                author={post.author}
                avatar={post.avatarUrl}
            />
        ));
    };

    return (
        <>
            <S.TitleWrapper>
                <S.Title>Trusted by over
                    {mobileOnly && <br />}
                    <S.Span> 5000+ influencers </S.Span>
                </S.Title>
            </S.TitleWrapper>
            <S.TextWrapper>
                <S.Text>Join thousands of influencers who trust Lyncsy to boost their online presence {!mobileOnly && <br></br>} and showcase their achievements</S.Text>
            </S.TextWrapper>
            {mobileOnly ? (
                <>
                    <Carousel
                        autoplay
                        autoplaySpeed={2800}
                        dots={false}
                        infinite
                        speed={800}
                        easing="ease-in-out"
                    >
                        {testimonials.length > 0 ? renderTestimonials() : null}
                    </Carousel>
                </>
            ) : (
                <>
                    <BaseCarousel
                        slidesToShow={3}
                        infinite
                        slidesToScroll={1}
                        autoplay
                        speed={8000}
                        autoplaySpeed={8000}
                        pauseOnHover={false}
                        responsive={[
                            {
                                breakpoint: 568,
                                settings: {
                                    slidesToShow: 1,
                                },
                            },
                            {
                                breakpoint: 992,
                                settings: {
                                    slidesToShow: 2,
                                },
                            },
                        ]}
                    >
                        {testimonials.length > 0 ? renderTestimonials() : null}
                    </BaseCarousel>
                </>
            )}
        </>
    );
};
