import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
import HomeLayout from '@app/components/layouts/HomeLayout/HomeLayout';
import HomePage from '@app/pages/home/HomePage';

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/login/LoginPage';
import SignUpPage from '@app/pages/login/SignUpPage';

const ProfileLayout = React.lazy(() => import('@app/components/profile/ProfileLayout'));
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

const ResumeDashboardPage = React.lazy(() => import('@app/pages/resume/ResumeDashboardPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ProfileInfoPage = React.lazy(() => import('@app/pages/profile/ProfileInfoPage'));
const SettingsPage = React.lazy(() => import('@app/pages/profile/SettingsPage'));
const SocialMediaAccountsPage = React.lazy(() => import('@app/pages/profile/SocialMediaAccountPage'));
const ExperiencePage = React.lazy(() => import('@app/pages/profile/ExperiencePage'));
const CollabsPage = React.lazy(() => import('@app/pages/profile/CollabPage'));
const PaymentResultPage = React.lazy(() => import('@app/pages/payment/PaymentResultPage'));
const PricingPage = React.lazy(() => import('@app/pages/payment/PricingPage'));
const PrivacyPolicyPage = React.lazy(() => import('@app/pages/legal/PrivacyPolicyPage'));
const TermsOfServicePage = React.lazy(() => import('@app/pages/legal/TermsOfServicePage'));
const FAQPage = React.lazy(() => import('@app/pages/faq/FAQPage'));
const MainLayout = React.lazy(() => import('@app/components/layouts/main/MainLayout/MainLayout'));
const Logout = React.lazy(() => import('./Logout'));


export const HOME_PATH = '/';
export const RESUME_DASHBOARD_PATH = '/:username';
export const PROFILE_PATH = '/profile';
export const AUTH_PATH = '/auth';
export const RESULT_PATH = '/result';
export const LEGAL_PATH = '/legal';
export const FAQ_PATH = '/faq';
export const PRICING_PATH = '/pricing';

const ResumeDashboard = withLoading(ResumeDashboardPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

//Legal
const PrivacyPolicy = withLoading(PrivacyPolicyPage);
const TermsOfService = withLoading(TermsOfServicePage);

//FAQ
const FAQ = withLoading(FAQPage);

// Profile
const ProfileInfo = withLoading(ProfileInfoPage);
const Settings = withLoading(SettingsPage);
const SocialMediaAccounts = withLoading(SocialMediaAccountsPage);
const Experience = withLoading(ExperiencePage);
const Collabs = withLoading(CollabsPage);

//Payment
const PaymentResult = withLoading(PaymentResultPage);
const Pricing = withLoading(PricingPage);

const MainLayoutFallback = withLoading(MainLayout);
const AuthLayoutFallback = withLoading(AuthLayout);
const ProfileLayoutFallback = withLoading(ProfileLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
      <Route path={HOME_PATH} element={<HomeLayout />}>
          <Route path="home" element={<HomePage />} />
        </Route>
        <Route path={RESUME_DASHBOARD_PATH} element={<MainLayoutFallback />}>
          <Route index element={<ResumeDashboard />} />
        </Route>
        <Route path={PROFILE_PATH} element={protectedLayout}>
          <Route element={<ProfileLayoutFallback />}>
            <Route path="profile-info" element={<ProfileInfo />} />
            <Route path="settings" element={<Settings />} />
            <Route path="sm-accounts" element={<SocialMediaAccounts />} />
            <Route path="experience" element={<Experience />} />
            <Route path="collabs" element={<Collabs />} />
          </Route>
        </Route>
        <Route path={RESULT_PATH} element={<MainLayout />}>
          <Route path="payment" element={<PaymentResult />} />
        </Route>

        <Route path={PRICING_PATH} element={<MainLayout />}>
          <Route path="pricing-info" element={<Pricing />} />
        </Route>

        <Route path={LEGAL_PATH} element={<MainLayout />}>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
        </Route>

        <Route path={FAQ_PATH} element={<MainLayout />}>
          <Route path="/faq" element={<FAQ />} />
        </Route>

        <Route path={AUTH_PATH} element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          {/* <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          /> */}
          {/* <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} /> */}
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="server-error" element={<ServerError />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
