import { css } from 'styled-components';

export const resetCss = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  // /* Safe Area Inset for iOS devices with notches */
  body {
    // padding-top: env(safe-area-inset-top);
    // padding-right: env(safe-area-inset-right);
    // padding-bottom: env(safe-area-inset-bottom);
    // padding-left: env(safe-area-inset-left);
    background-color: var(--body-background-color);
   // font-weight: 500;
  }
  
  ::-webkit-scrollbar {
    width: 1.2rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-left: 1px solid var(--tag-background-color);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 1.25rem;
    border: 0.375rem solid transparent;
    background-clip: content-box;
  }

  img {
    display: block;
  }
`;
