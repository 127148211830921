import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ContractModel, ContractUpdateModel } from '@app/domain/ContractModel';
import { getContracts, upsertContract } from '@app/api/contract.api';
import { contractStatus } from '@app/locales/constants';

export interface ContractState {
  contracts: ContractModel[] | null;
}

const initialState: ContractState = {
  contracts: null,
};

export const doGetContracts = createAsyncThunk('contracts/get-contract', async () => {
  try {
    const response = await getContracts();
    return response;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdateContract = createAsyncThunk('contracts/update-contract', async (request: ContractUpdateModel) => {
  try {
    const response = await upsertContract(request);
    return { request };
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(doGetContracts.fulfilled, (state, action) => {
      state.contracts = action.payload;
    });
    builder.addCase(doUpdateContract.fulfilled, (state, action) => {
      const { request } = action.payload;
      if (request && request.databaseId && state.contracts) {
        // Find the index of the contract to be updated
        const contractIndex = state.contracts?.findIndex(contract => contract.databaseId === request.databaseId);
        if (contractIndex !== -1) {
          // Update the contract's status
          state.contracts[contractIndex].status = request.status;
          state.contracts[contractIndex].didRequestorApprove = request.didRequestorApprove;
          state.contracts[contractIndex].isEditable = !(request.didRequestorApprove && (request.status === contractStatus.completed || request.status === contractStatus.canceled));
        }
      }
    });
  },
});

export default contractSlice.reducer;
