import React from 'react';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PageTitles } from '@app/locales/constants';

const LoginPage: React.FC = () => {

  return (
    <>
      <PageTitle>{PageTitles.Login}</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;
