import React from 'react';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { PageTitles } from '@app/locales/constants';

const SignUpPage: React.FC = () => {

  return (
    <>
      <PageTitle>{PageTitles.Signup}</PageTitle>
      <LoginForm isSignUp />
    </>
  );
};

export default SignUpPage;
