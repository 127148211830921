import React from 'react';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { labels, notification, signUpInstructions, socialMediaTypes, validations } from '@app/locales/constants';
import * as S from './AuthModal.styles';
import { SocialMediaType } from '@app/interfaces/interfaces';
import GoogleIcon from '@app/assets/icons/google-icon.svg';
import FacebookIcon from '@app/assets/icons/facebook-dark-icon.svg';
import TikTokIcon from '@app/assets/icons/tiktok-icon.svg';
import { AsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { notificationController } from '@app/controllers/notificationController';
import { doFacebookLogin, doGoogleLogin, doTiktokLogin } from '@app/store/slices/authSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { readToken } from '@app/services/localStorage.service';
import { Link } from 'react-router-dom';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Form } from 'antd';

interface AuthModalProps {
    isModalOpen: boolean;
    isFromProfile?: boolean; //To distinguish bet from login or sm acct (backend call to connect acct)
    isSignUp?: boolean; //To change title and checkbox to agree to terms and policy
    socialMediaType?: SocialMediaType;
    handleCancel: () => void;
}

export const AuthModal: React.FC<AuthModalProps> = ({ isModalOpen, isFromProfile = false, isSignUp = false, socialMediaType, handleCancel }) => {
    if (!socialMediaType) return null;

    const dispatch = useAppDispatch();
    const { mobileOnly } = useResponsive();

    const instruction = signUpInstructions[socialMediaType];

    const renderAvatar = (socialMediaType: SocialMediaType) => {
        const iconMap = {
            [socialMediaTypes.YouTube]: GoogleIcon,
            [socialMediaTypes.Instagram]: FacebookIcon,
            [socialMediaTypes.TikTok]: TikTokIcon,
        };
        const icon = iconMap[socialMediaType];
        return <S.IconAvatar size={18} src={icon} />;
    };

    const handleSocialSignIn = async (loginAction: AsyncThunk<string, void, AsyncThunkConfig>) => {
        try {
            //Check if it's from login or if it's from profile (user connecting sm account)
            //If from profile, connect sm account else from login, check if token exist and redirect without call to backend
            if (isFromProfile || !readToken()) {
                const res = await dispatch(loginAction()).unwrap();
                window.location.href = res;
            } else {
                setTimeout(() => {
                    notificationController.success({ message: notification.messages.userSignedIn });
                }, 1000);
                window.location.href = '/profile/profile-info';
            }
        } catch (err: any) {
            notificationController.error({ message: err.message });
        }
    };

    const handleSignIn = (socialMediaType: SocialMediaType) => {
        switch (socialMediaType) {
            case socialMediaTypes.YouTube:
                handleSocialSignIn(doGoogleLogin);
                break;
            case socialMediaTypes.Instagram:
                handleSocialSignIn(doFacebookLogin);
                break;
            case socialMediaTypes.TikTok:
                handleSocialSignIn(doTiktokLogin);
                break;
            default:
                console.error(`Unsupported social media type: ${socialMediaType}`);
        }
    };

    const [form] = BaseButtonsForm.useForm();

    const handleCancelModal = async () => {
        form.resetFields();
        handleCancel();
    };

    const handleFormSubmit = async () => {
        try {
            await form.validateFields();
            handleSignIn(socialMediaType);
          } catch (error) {
            console.error('Failed to submit form:', error);
          }
    };

    return (
        <S.StyledModal
            size='large'
            open={isModalOpen}
            onCancel={handleCancelModal}
            footer={null}
        >
            <Form form={form}>
                <BaseRow justify="start" gutter={[0, 15]}>
                    <BaseCol xs={24}>
                    <S.Title>{instruction.title}</S.Title>
                    {/* <div>
                        <S.Description>{instruction.description}</S.Description>
                    </div> */}
                    </BaseCol>
                    <BaseCol xs={24}>
                        <S.Subtitle>{instruction.subTitle}</S.Subtitle>
                    </BaseCol>
                    {instruction.instructions.map((text, index) => (
                        <>
                            <BaseCol xs={2} key={index}>
                                <S.Span>{index + 1}. </S.Span>
                            </BaseCol >
                            <BaseCol xs={22} key={index + '1'}>
                                <S.Text>{text.title}</S.Text>
                                <S.Span> {text.estimatedTime}</S.Span>
                                <div>
                                    <S.StyledLink href={text.link} target="_blank" rel="noopener noreferrer">
                                        {text.linkText}
                                    </S.StyledLink>
                                </div>
                            </BaseCol >
                        </>
                    ))}
                    {isSignUp &&
                        <>
                            <BaseCol xs={24}>
                                <S.FormItem
                                    name="termsAgreement"
                                    valuePropName="checked"
                                    rules={[{
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(validations.mustCheckTermsAndPolicy),
                                    }]}
                                >
                                    <S.FormCheckbox>
                                        <S.Text>
                                            By signing up, you agree to the{' '}
                                            <Link to="/legal/terms-of-service" target={'_blank'}>
                                                <S.LinkText>{labels.termsOfService}</S.LinkText>
                                            </Link>{' '}
                                            and{' '}
                                            <Link to="/legal/privacy-policy" target={'_blank'}>
                                                <S.LinkText>{labels.privacyPolicy}</S.LinkText>
                                            </Link>
                                        </S.Text>
                                    </S.FormCheckbox>
                                </S.FormItem>
                            </BaseCol>
                        </>
                    }
                    <BaseCol xs={24}>
                        <S.StyledButton
                            $textColor={instruction.button.textColor}
                            $backgroundColor={instruction.button.backgroundColor}
                            icon={renderAvatar(socialMediaType)}
                            size={mobileOnly ? 'middle' : 'large'}
                            onClick={handleFormSubmit}
                        >
                            {instruction.button.title}
                        </S.StyledButton>
                    </BaseCol>
                    <BaseCol xs={24}>
                        <S.LimitationText style={{ whiteSpace: 'pre-line' }}>{instruction.limitationText}</S.LimitationText>
                    </BaseCol>
                </BaseRow>
            </Form>
        </S.StyledModal>
    );
};


