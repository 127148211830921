import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  googleLogin,
  facebookLogin,
  tiktokLogin
} from '@app/api/auth.api';
import { deleteToken, readToken } from '@app/services/localStorage.service';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doGoogleLogin = createAsyncThunk<string>('auth/googleauth', async () => {
  try {
    const res = await googleLogin();
    return res;
  } catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doFacebookLogin = createAsyncThunk<string>('auth/facebookauth', async () => {
  try {
    const res = await facebookLogin();
    return res;
  } catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doTiktokLogin = createAsyncThunk<string>('auth/tiktokauth', async () => {
  try {
    const res = await tiktokLogin();
    return res;
  } catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

export default authSlice.reducer;


//TODO: manual login / sign up
// export const doLogin = createAsyncThunk('auth/login', async (request: LoginRequest, { dispatch }) => {
//   try {
//     const res = await login(request);
//     res.influencer = InfluencerTransformer.databaseModelToInfluencerModel(res.influencer);

//     dispatch(setInfluencer(res.influencer));
    
//     persistToken(res.token);
//     return res;
//   } catch (error: any) {
//     throw new Error(`${error.message}:${error.options}`);
//   }
// });

// export const doSignUp = createAsyncThunk('auth/sign-up', async (request: SignUpRequest, { dispatch }) => {
//   try {
//     const res = await signUp(request);
//     res.influencer = InfluencerTransformer.databaseModelToInfluencerModel(res.influencer);

//     dispatch(setInfluencer(res.influencer));

//     persistToken(res.token); 
//     return res;
//   } catch (error: any) {
//     throw new Error(`${error.message}:${error.options}`);
//   }
// });

// export const doResetPassword = createAsyncThunk(
//   'auth/doResetPassword',
//   async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
// );

// export const doVerifySecurityCode = createAsyncThunk(
//   'auth/doVerifySecurityCode',
//   async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
// );

// export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
//   setNewPassword(newPasswordData),
// );
