import styled from 'styled-components';
import { BASE_COLORS, BORDER_RADIUS, BREAKPOINTS, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { CheckOutlined } from '@ant-design/icons';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

export const Video = styled.video`
  border-radius: ${BORDER_RADIUS};
  box-shadow: -3px 5px 12px rgb(0 0 0/3%), -3px -5px 12px rgb(0 0 0/1%);
  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    margin-top: 2rem;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.7rem;
  }
 
  @media only screen and ${media.xl} {
    margin-bottom: 1rem;
  }
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--text-main-color);

  @media only screen and ${media.sm} {
    font-size: 2.75rem !important;
  }

  @media only screen and ${media.md} {
    font-size: 2.75rem !important;
  }

  @media only screen and ${media.lm} {
    font-size: 3.5rem !important;
  }

  @media only screen and ${media.xl} {
    font-size: 3.5rem !important;
  }

  @media only screen and ${media.xxxl} {
    font-size: 3.5rem !important;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
      line-height: 1.5;
      font-weight: 800;
  }
`;

export const TitleSpan = styled.span`
  font-weight: 800;
  color: var(--primary-color); 
    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    line-height: 1.5;
  }
`;

export const TextWrapper = styled.div`
    margin-bottom: 1.75rem;

    @media only screen and ${media.md} {
    margin-bottom: 2.25rem;
    }

    @media only screen and ${media.xl} {
    margin-bottom: 2.5rem;
    }
`;

export const Text = styled(BaseTypography.Text)`
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-light-color);
  line-height: 2;

  @media only screen and ${media.md}  and (max-width: 1919px) {
    font-size: 1rem !important;
  }

  @media only screen and ${media.xxxl} {
    font-size: 1.25rem !important;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    line-height: 1.7;
  }
`;

export const TextHeader = styled(Text)`
  font-weight: 800;
  color: var(--text-light-color);
`;

export const Span = styled.span`
  font-weight: 800;
  color: var(--primary-color);
`;

export const CheckIcon = styled(CheckOutlined)`
  color: var(--primary-color);
  margin-right: 0.5rem;
  @media only screen and ${media.md} {
    font-size: 0.9rem;
  }

  @media only screen and ${media.xl} {
    font-size: 0.9rem;
  }

  @media only screen and ${media.xl} {
    font-size: 1.1rem;
  }
`;

export const BtnWrapper = styled(BaseRow)`
  justify-content: start;
`;

export const DemoBtn = styled(BaseButton)`
  font-weight: 800;
  background: ${BASE_COLORS.white};
  color: var(--primary-color);
`;

export const SignUpBtn = styled(BaseButton)`
  font-weight: 800;
  margin-right: 1rem;
`;


