import styled, { css } from 'styled-components';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { LAYOUT, media, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import { BaseCollapse } from '../common/BaseCollapse/BaseCollapse';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseButton } from '../common/BaseButton/BaseButton';

interface BtnIconProps {
  $isOpened: boolean;
}

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: ${FONT_SIZE.xl};

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }

   .ant-input-group-wrapper {
    display: block;
  }
`;

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: ${FONT_WEIGHT.semibold}; 
    font-size: ${FONT_SIZE.xs};

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: ${FONT_SIZE.md};
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BtnIcon = styled(BaseButton)<BtnIconProps>`
&& {
  border: none;
   box-shadow: none;
   background-color: ${({ $isOpened }) => ($isOpened ? 'var(--primary-color)' : 'var(--item-hover-bg)')};
   //background-color: var(--item-hover-bg);
}
`;

export const BurgerCol = styled(BaseCol)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(BaseCol) <ProfileColumn>`
`;

