import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BASE_COLORS, BREAKPOINTS, FONT_WEIGHT} from '@app/styles/themes/constants';
import styled from 'styled-components';

interface FromHome {
  $isFromHome: boolean;
}

export const Space = styled.div`
    margin-top: 7rem;
`;

export const BottomBar = styled(BaseRow)`
  border-top: 1px solid var(--tag-background-color);
  padding: 2rem 0rem 1rem 0rem;
  margin: 0;
  line-height: 2rem;
  display: flex;
`;


export const StyledFooter = styled(BaseLayout.Footer)<FromHome>`
  border-top: 1px solid var(--tag-background-color);
  clear: both; 
  background: var(--layout-header-bg-color);
  position: relative;
  z-index: 100;
  
  color: ${(props) => {
    if (props.$isFromHome) {
      return 'var(--text-main-color)';
    } else {
      return 'var(--text-main-color)';
    }
  }};

  .ant-row {
    text-align: center;
  }
`;

export const FooterTitle = styled(BaseTypography.Text)<FromHome>`
  ${(props) => {
    if (props.$isFromHome) {
      return `
        color: var(--text-main-color);
        //font-weight: ${FONT_WEIGHT.extraBold};
      `;
    } else {
      return 'var(--text-main-color)';
    }
  }};
`;

export const FooterWrapper = styled.div`
  position: relative;
  padding: 3.75rem  5.8rem;

  @media only screen and (max-width: ${BREAKPOINTS.ml - 0.02}px) {
    padding: 3.75rem 1rem 3.75rem 1.8rem;
  }
`;

export const FooterCenter = styled.div`
  display: inline-block;
  text-align: left;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    display: block;
  }

  > h2 {
    font-size: 1rem;
    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
      font-size: 0.85rem;
    }
    margin: 0 auto 1.5rem;
    font-weight: 500;
    position: relative;
  }

  > div {
    margin: 0.75rem 0;
  }
`;

export const FooterCol = styled(BaseCol)`
  margin-bottom: 1rem;
`;


export const StyledLink = styled.a<FromHome>`
  color: ${(props) => {
    if (props.$isFromHome) {
      return 'var(--text-light-color)'; //BASE_COLORS.white;
    } else {
      return 'var(--text-light-color)';
    }
  }};

  text-decoration: none;
  transition: color 0.3s, text-decoration 0.3s; 
  
  &:hover {
    color: var(--secondary-color) !important;
    text-decoration: underline; 
  }
`;


export const IconsWrapper = styled.span<FromHome>`
  margin-left: 2rem;
  line-height: 1.5rem;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
     margin-left: 0rem;
  }
  svg {
    color: ${(props) => {
      if (props.$isFromHome) {
        return 'var(--text-light-color)';// BASE_COLORS.white;
      } else {
        return 'var(--text-light-color)';
      }
    }};
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const BrandWrapper = styled.span`
  white-space: pre-wrap;
  margin-left: 0.5rem;
`;

export const LogoAvatar = styled(BaseAvatar)`
margin-left: 0.5rem;
margin-right: 0.5rem;
  &.ant-avatar-square {
    img {
      object-fit: contain;
    }
  }
`;
