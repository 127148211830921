import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApplicationModel, ApplicationUpsertModel } from '@app/domain/ApplicationModel';
import { getApplications, upsertApplication } from '@app/api/application.api';
import { applicationStatus } from '@app/locales/constants';

export interface ApplicationState {
  applications: ApplicationModel[] | null;
}

const initialState: ApplicationState = {
  applications: null,
};

export const doGetApplications = createAsyncThunk('applications/get-applications', async () => {
  try {
    const response = await getApplications();
    return response;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpsertApplication = createAsyncThunk('applications/upsert-application', async (request: ApplicationUpsertModel) => {
  try {
    const response = await upsertApplication(request);
    return response;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdateApplication = createAsyncThunk('applications/upsert-application', async (request: ApplicationUpsertModel) => {
  try {
    const response = await upsertApplication(request);
    return { request };
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(doGetApplications.fulfilled, (state, action) => {
      state.applications = action.payload;
    });
    builder.addCase(doUpdateApplication.fulfilled, (state, action) => {
      const { request } = action.payload;
      if (request && request.databaseId && state.applications) {
        // Find the index of the contract to be updated
        const applicationIndex = state.applications?.findIndex(app => app.databaseId === request.databaseId);

        if (applicationIndex !== -1) {
          // Update the contract's status
          state.applications[applicationIndex].status = request.status;
          state.applications[applicationIndex].additionalInfo = request.additionalInfo;
          state.applications[applicationIndex].isEditable = !(request.didRequestorApprove && request.status === applicationStatus.approved)
        }
      }
    });
  },
});

export default applicationSlice.reducer;
