import { httpApi } from '@app/api/http.api';

export const googleLogin = async (): Promise<string> => {
  const response = await httpApi.get<string>('auth/googleauth');
  return response.data;
};

export const facebookLogin = async (): Promise<string> => {
  const response = await httpApi.get<string>('auth/facebookauth');
  return response.data;
};

export const tiktokLogin = async (): Promise<string> => {
  const response = await httpApi.get<string>('auth/tiktokauth');
  return response.data;
};


//TODO: Manual sign up / login
//export interface SignUpRequest {
//   email: string;
//   password: string;
// }

// export interface LoginRequest {
//   email: string;
//   password: string;
// }

// export interface LoginResponse {
//   token: string;
//   influencer: InfluencerModel;
// }

// export interface SignUpResponse {
//   token: string;
//   influencer: InfluencerModel;
// }

// export interface GoogleLoginResponse {
//   data: string;
// }
// export const login = async (request: LoginRequest): Promise<LoginResponse> => {
//   const response = await httpApi.get<LoginResponse>('auth/login', { params: request });
//   return response.data;
// };

// export const signUp = async (request: SignUpRequest): Promise<SignUpResponse> => {
//   const response = await httpApi.post<SignUpResponse>('auth/sign-up', { ...request });
//   return response.data;
// };