import axios, {AxiosRequestConfig} from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  withCredentials: true
});

httpApi.interceptors.request.use((config: any) => {
  const token = readToken();
  if (token){
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  }

  //TODO: might be needed later
  // if (config.url === 'influencers/update-profile-image' && config.method === 'post') {
  //   config.maxContentLength = 2 * 1024 * 1024; // 2MB
  //   config.maxBodyLength = 2 * 1024 * 1024;    // 2MB
  // }
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>((error.response?.data as ApiErrorData)?.message || error.message, error.response?.data as ApiErrorData);
});

export interface ApiErrorData {
  message: string;
}
