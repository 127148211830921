import { Address } from "@app/domain/InfluencerModel";

export class AddressTransformer {
    static transform(values: any): Address {
      return {
        country: values.country,
        province: values.province,
        city: values.city,
        primaryAddress: values.primaryAddress,
        secondaryAddress: values.secondaryAddress,
        zipCode: values.zipCode,
        postalCode: values.postalCode,
      }
    }
  }