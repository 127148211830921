const avatar1 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar1';
const avatar2 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar2';
const avatar3 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar3';
const avatar4 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar4';
const avatar5 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar5';
const avatar6 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar6';
const avatar7 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar7';
const avatar8 = process.env.REACT_APP_ASSETS_BUCKET + '/testimonials/avatars/avatar8';

export interface Post {
  id: number;
  avatarUrl: string;
  author: string;
  handleName: string;
  title?: string;
  rating: number;
  text: string; 
}


export const getFeedbacks = async (): Promise<Post[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          id: 2,
          avatarUrl: avatar2,
          author: 'James Howard',
          handleName: 'YouTuber, Instagramer',
          rating: 5,
          text: "This app is amazing! It has helped me showcase my achievements and analytics across all platforms. The interface is user-friendly, and I can easily track my progress and engage with my audience. It's a must-have for any influencer."
        },
        {
          id: 1,
          avatarUrl: avatar1,
          author: 'Victoria Taylor',
          handleName: 'YouTuber',
          rating: 4.7,
          text: "I absolutely love using this app! It's a game-changer for influencers like me. Having all my posts and analytics in one place has made managing my online presence so much easier. I highly recommend it to anyone looking to boost their social media presence."
        },
        {
          id: 3,
          avatarUrl: avatar3,
          author: 'Olivia Chen',
          handleName: 'Instagamrer, TikToker',
          rating: 5,
          text: "I'm so glad I found this app! It has made managing my posts and analytics a breeze. The ability to showcase my achievements has helped me stand out and attract more followers. I highly recommend it to any influencer looking to boost their online presence"
        },
        {
          id: 4,
          avatarUrl: avatar4,
          author: 'Amelia Garcia',
          handleName: 'Instagramer, TikToker',
          rating: 4.5,
          text: "I've tried many apps, but this one is by far the best for influencers. It's intuitive, easy to use, and has all the features I need to showcase my achievements and boost my online presence. I can't imagine managing my social media without it."
        },
        {
          id: 5,
          avatarUrl: avatar5,
          author: 'Liam Rodriguez',
          handleName: 'YouTuber, Twitch Streamer',
          rating: 5,
          text: "I've been using this app for a while now, and it has exceeded my expectations. The ability to showcase my analytics and achievements has helped me grow my online presence significantly. I highly recommend it to any influencer looking to take their social media game to the next level."
        },
        {
          id: 6,
          avatarUrl: avatar6,
          author: 'Ava Ramirez',
          handleName: 'YouTuber, Twitch Streamer',
          rating: 4.7,
          text: "I can't say enough good things about this app! It has helped me streamline my social media management and showcase my achievements in a way that resonates with my audience. It's a must-have for any influencer serious about growing their online presence."
        },
        {
          id: 7,
          avatarUrl: avatar7,
          author: 'Emma Kim',
          handleName: 'YouTuber, Twitch Streamer',
          rating: 4,
          text: "This app has been a game-changer for me! It has all the tools I need to manage my posts and analytics effectively. The ability to showcase my achievements has helped me attract more followers and grow my online presence. I highly recommend it to any influencer looking to level up their social media game."
        },
        {
          id: 8,
          avatarUrl: avatar8,
          author: 'Sofia Nguyen',
          handleName: 'Instagramer, Twitch Streamer',
          rating: 4.8,
          text: "I've tried several apps for managing my social media, but this one stands out. It's user-friendly, intuitive, and has all the features I need to showcase my achievements and boost my online presence. I highly recommend it to any influencer serious about growing their social media following."
        },
      ]);
    }, 1000);
  });
};
