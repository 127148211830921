import React from 'react';
import { Helmet } from 'react-helmet-async';
import { labels } from '@app/locales/constants';

interface PageTitleProps {
  children: React.ReactNode;
  description?: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ children, description }) => {
  return (
    <Helmet>
      <title>{labels.brandName} | {children}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
