import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { BREAKPOINTS, media } from '@app/styles/themes/constants';
import styled, { css } from 'styled-components';

interface Header {
  $isTwoColumnsLayoutHeader: boolean;
  $isTransparent: boolean;
  $isFromHome: boolean;
}

export const Header = styled(BaseLayout.Header) <Header>`
  height: 4rem;
  background: var(--background-color);
  z-index: 999;
  ${(props) => props.$isFromHome && `
    position: sticky;
 `}
  top: 0;
  border-bottom: 1px solid var(--tag-background-color);

  ${(props) =>
    props?.$isTransparent &&
    css`
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
      transition: border .3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background .3s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
    `}

    &&& {
      padding: 0;  
    }

   padding: 0rem 4.5rem !important;  

   @media only screen and (max-width: ${BREAKPOINTS.md - 0.02}px) {
    height: 3rem;
    padding: 0rem 1rem !important;
    line-height: 2.8rem;
   }

   @media only screen and ${media.xl} {
    padding: 0rem 4.5rem !important; 
  }

  @media only screen and ${media.xxl} {
    padding: 0rem 6.7rem !important; 
  }

  @media only screen and ${media.xxxl} {
    padding: 0rem 16.7rem !important; 
  }

    ${(props) =>
    props?.$isFromHome &&
    css`
      border-bottom: none !important; 
      height: 4rem !important;
      line-height: 3.8rem !important;
      padding: 0rem 3.25rem !important;
      
      @media only screen and ${media.xl} {
        padding: 0rem 5.25rem !important; 
      }
    
      @media only screen and ${media.xxl} {
        padding: 0rem 7rem !important; 
      }
    
      @media only screen and ${media.xxxl} {
        padding: 0rem 9.25rem !important; 
      }
      `}
`;
