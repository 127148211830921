import styled from 'styled-components';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';

export const HeaderLogo = styled(HeaderActionWrapper)`
  cursor: pointer;
`;

export const LogoAvatar = styled(BaseAvatar)`
  &.ant-avatar-square {
    img {
      object-fit: contain;
    }
  }
`;