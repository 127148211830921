import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UpdatePostVisibilityRequest, GetPostMetricThumbnailsRequest, getInfluencer, getInfluencerByCustomUrl, getPostMetricThumbnails, updateExperience, updateCollab, updateInfluencer, updatePostVisibility, updateProfileImage, updateSocialMediaAccount, deleteInfluencer, deleteCollab } from '@app/api/influencer.api';
import { Collab, Experience, InfluencerModel, PersonalInfo, SocialMediaAccount } from '@app/domain/InfluencerModel';
import { InfluencerTransformer } from '@app/transformers/InfluencerTransformers';
import { FileUpload } from '@app/interfaces/interfaces';
import { AnalyticsModel, PostMetrics, PostMetricsModel } from '@app/domain/AnalyticsModel';

export interface InfluencerState {
  influencer: InfluencerModel | null;
  profile: InfluencerModel | null;
  profilePostMetrics: PostMetricsModel | null;
  analytics: AnalyticsModel | null;
  postMetrics: PostMetricsModel | null;
}

const initialState: InfluencerState = {
  influencer: null,
  profile: null,
  profilePostMetrics: null,
  analytics: null,
  postMetrics: null,
};

export const doGetInfluencer = createAsyncThunk('influencers/get-influencer', async () => {
  try {
    const res = await getInfluencer();
    res.influencer = InfluencerTransformer.databaseModelToInfluencerModel(res.influencer);
    return res;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doGetInfluencerByCustomUrl = createAsyncThunk('influencers/get-influencer/custom-url', async (request: string) => {
  try {
    const res = await getInfluencerByCustomUrl(request);
    res.profile = InfluencerTransformer.databaseModelToInfluencerModel(res.profile);
    return res;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doGetPostMetricThumbnails = createAsyncThunk('influencers/get-post-thumbnails', async (request: GetPostMetricThumbnailsRequest) => {
  try {
    const res = await getPostMetricThumbnails(request);
    return res;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});


export const doUpdateInfluencer = createAsyncThunk('influencers/update-influencer', async (request: PersonalInfo) => {
  try {
    const res = await updateInfluencer(request);
    const influencer = InfluencerTransformer.databaseModelToInfluencerModel(res);
    return influencer;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdateSocialMediaAccount = createAsyncThunk('influencers/update-sm-account', async (request: SocialMediaAccount) => {
  try {
    const res = await updateSocialMediaAccount(request);
    const influencer = InfluencerTransformer.databaseModelToInfluencerModel(res);
    return influencer;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdateExperience = createAsyncThunk('influencers/update-experience', async (request: Experience) => {
  try {
    const res = await updateExperience(request);
    const influencer = InfluencerTransformer.databaseModelToInfluencerModel(res);
    return influencer;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdateCollab = createAsyncThunk('influencers/update-collab', async (request: Collab) => {
  try {
    const res = await updateCollab(request);
    const influencer = InfluencerTransformer.databaseModelToInfluencerModel(res);
    return influencer;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doDeleteCollab = createAsyncThunk('influencers/delete-collab', async (request: string) => {
  try {
    const res = await deleteCollab(request);
    console.log(res);
    const influencer = InfluencerTransformer.databaseModelToInfluencerModel(res);
    return influencer;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});


export const doUpdateProfileImage = createAsyncThunk('influencers/update-profile-image', async (request: FileUpload) => {
  try {
    const res = await updateProfileImage(request);
    res.influencer = InfluencerTransformer.databaseModelToInfluencerModel(res.influencer);
    return res;
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doUpdatePostVisibility = createAsyncThunk('influencers/update-post-visibility', async (request: UpdatePostVisibilityRequest) => {
  try {
    const response = await updatePostVisibility(request);
    return { request, response };
  }
  catch (error: any) {
    throw new Error(`${error.message}:${error.options}`);
  }
});

export const doDeleteInfluencer = createAsyncThunk('influencers/delete-influencer', async () => {
  try {
    const res = await deleteInfluencer();
    return res;
  }
  catch (error: any) {
    console.log(error.message, 'slice');
    throw new Error(`${error.message}:${error.options}`);
  }
});

const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {
    setInfluencer: (state, action: PayloadAction<InfluencerModel | null>) => {
      state.influencer = action.payload;
    },
    updateProfilePostMetrics: (state, action: PayloadAction<PostMetricsModel>) => {
      state.profilePostMetrics = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetInfluencer.fulfilled, (state, action) => {
      state.influencer = action.payload.influencer;
      state.postMetrics = action.payload.postMetrics;
    });
    builder.addCase(doGetInfluencerByCustomUrl.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.analytics = action.payload.analytics;
    });
    builder.addCase(doGetPostMetricThumbnails.fulfilled, (state, action) => {
      state.profilePostMetrics = action.payload;
    });
    builder.addCase(doUpdateInfluencer.fulfilled, (state, action) => {
      state.influencer = action.payload;
    });
    builder.addCase(doUpdateCollab.fulfilled, (state, action) => {
      state.influencer = action.payload;
    });
    builder.addCase(doDeleteCollab.fulfilled, (state, action) => {
      state.influencer = action.payload;
    });
    builder.addCase(doUpdateSocialMediaAccount.fulfilled, (state, action) => {
      state.influencer = action.payload;
    });
    builder.addCase(doUpdateExperience.fulfilled, (state, action) => {
      state.influencer = action.payload;
    });
    builder.addCase(doUpdateProfileImage.fulfilled, (state, action) => {
      state.influencer = action.payload.influencer;
    });
    builder.addCase(doUpdatePostVisibility.fulfilled, (state, action) => {
      const { request, response } = action.payload;
      // Ensure state.postMetrics is defined
      if (!state.postMetrics) {
        state.postMetrics = {};
      }
      // Ensure the array for the specific social media type is defined
      if (!state.postMetrics[request.socialMediaType]) {
        state.postMetrics[request.socialMediaType] = [];
      }
      if(response){
        const posts = state.postMetrics[request.socialMediaType] as PostMetrics[];
        // Find the index of the post to update
        const index = posts.findIndex(item => item.id === request.postId);

        if (index !== -1) {
          // Update the found post with the new visibility status
          posts[index] = {
            ...posts[index],
            isVisible: request.isVisible, 
          };
        } 
        // Assign the modified posts array back to the state
        state.postMetrics[request.socialMediaType] = posts;
      }
    });
  },
});

export const { setInfluencer, updateProfilePostMetrics } = influencerSlice.actions;

export default influencerSlice.reducer;
