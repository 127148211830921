import React, { useEffect, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { HeaderLogo } from '@app/components/header/components/HeaderLogo/HeaderLogo';
import { MainHeader } from '@app/components/layouts/main/MainHeader/MainHeader';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './Header.styles';
import { companyContactEmail, labels } from '@app/locales/constants';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const Header: React.FC = () => {
    const user = useAppSelector((state) => state.influencer.influencer);
    const { mobileAndTabletOnly } = useResponsive();

    const menuMode = mobileAndTabletOnly ? 'inline' : 'horizontal';
    const navigate = useNavigate();

    const [isHeaderTransparent, setIsHeaderTransparent] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const isTransparent = scrollTop > 40;
            setIsHeaderTransparent(isTransparent);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const redirectToPricing = () => {
        window.location.href = '/pricing/pricing-info';
    };

    const redirectToSignUp = () => {
        navigate('/auth/sign-up');
    };

    const redirectToDemo = () => {
        navigate(`/${process.env.REACT_APP_DEMO_USER}`);
    };

    const redirectToDonationLink = () => {
        window.open(process.env.REACT_APP_STRIPE_DONATION_LINK, '_blank');
    };

    const redirectToFAQ= () => {
        window.open(`${process.env.REACT_APP_BASE_URL}faq`, '_blank');
    };

    const sendEmail = () => {
        const subject = encodeURIComponent(labels.feedbackEmailSubject);
        window.open(`mailto:${companyContactEmail}?subject=${subject}`);
    };
 
    const menuContent = [
        { key: 'home', label: 'Home' },
        {
            key: 'faq',
            label: 'FAQ',
            onClick: redirectToFAQ,
        },
        {
            key: 'pricing',
            label: 'Pricing',
            onClick: redirectToPricing,
        },
        {
            key: 'demo',
            label: 'View Demo',
            onClick: redirectToDemo,
        },
    ];

    if (mobileAndTabletOnly) {
        menuContent.push(
            {
                key: 'feedback',
                label: 'Feedback',
                onClick: sendEmail,
            },
            {
                key: 'sign-up',
                label: 'Sign Up',
                onClick: redirectToSignUp,
            }
        );
    }

    if (user) {
        const donationContent = {
            key: 'donation',
            label: 'Donation',
            onClick: redirectToDonationLink,
        }
        menuContent.push(donationContent);
    }

    return (
        <MainHeader isTransparent={isHeaderTransparent} isTwoColumnsLayout={true} isFromHome>
            <BaseRow justify="space-between" align="middle" style={{ height: '80%' }}>
                {menuMode === 'inline' ? (
                    <>
                        <BaseCol>
                            <HeaderLogo isFromHome />
                        </BaseCol>
                        <BaseCol>
                            <BaseRow justify="end" align="middle">
                                {/* <BaseCol>
                                    <Link to="/auth/sign-up">
                                        <S.LinkText>{labels.join}</S.LinkText>
                                    </Link>
                                </BaseCol> */}
                                <S.MobileSignUpCol>
                                    <BaseButton type="primary" onClick={redirectToSignUp}>
                                        {labels.join}
                                    </BaseButton>
                                </S.MobileSignUpCol>
                                <S.MobileBugerMenuCol>
                                    <BasePopover
                                        content={<S.HeaderMenu mode={menuMode} defaultSelectedKeys={['home']} id="nav" key="nav" items={menuContent} />}
                                    >
                                        <S.BurgerCol>
                                            <S.MobileBurger />
                                        </S.BurgerCol>
                                    </BasePopover>
                                </S.MobileBugerMenuCol>
                            </BaseRow>
                        </BaseCol>
                    </>
                ) : (
                    <>
                        <BaseCol>
                            <HeaderLogo isFromHome />
                        </BaseCol>

                        <BaseCol>
                            <BaseRow justify="end" align="middle">
                                <BaseCol>
                                    {menuMode === 'horizontal' ? (
                                        <S.HeaderMenu
                                            mode={menuMode}
                                            theme="light"
                                            defaultSelectedKeys={['home']}
                                            id="nav"
                                            key="nav"
                                            items={menuContent}
                                        />
                                    ) : null}
                                </BaseCol>
                                <S.SignUpCol>
                                    <BaseButton type="primary" onClick={redirectToSignUp}>
                                        {labels.signUp}
                                    </BaseButton>
                                </S.SignUpCol>
                            </BaseRow>
                        </BaseCol>
                    </>
                )}
            </BaseRow>
        </MainHeader>
    );
};
