const connectAccount = (socialMediaType: string) => `Connect ${socialMediaType} Account`;
const postPlaceholder = (socialMediaType: string) => `https://www.${socialMediaType.toLowerCase()}.com/post `;
const emailBody = (name: string) => `Hi ${name.toLowerCase()},\n\nYour profile on Lyncsy caught my eye, and I'm truly impressed by the content you create! Your creativity and authenticity are exactly what we're looking for, and we see a wonderful opportunity for collaboration.`;

export const labels = {
    //HOME
    //HEADER
    feedbackEmailSubject: "App Feedback - Lyncsy",
    //BANNER
    signUpNow: "Sign Up Now",
    getStarted: "Get Started",
    previewDemo: "Preview Demo",
    //FEATURE
    featureTitle: "Streamline Social Media Content Management \n with Instant Analytics Access",
  //  comprehensiveAnalytics: "Brands prioritize metrics, Lyncsy provides the in-depth analytics you need",
    comprehensiveAnalytics: "Analytics to enhance your profile's credibility",
    seeAnalyticsDemo: "See Analytics Demo",
    contentResume: "All your content in one place, Lyncsy provides easy access to your entire profile ",
    //collabResume: "Lyncsy provides easy access and management to your collab options",
    collabMarketing:"Easily connect & start collabs with any creators",
    seeCollabsDemo: "See Collabs Demo",
    collabSetUp: "Set up your collab options in minutes, and start requesting or receiving collabs from brands, influencers, and followers",
    //contentManagement: "Brands, collaborators, and followers can engage with your content hassle-free from a single link",
    analyticsBenefit: "Lyncsy provides metrics, audience and overview analytics to add credibility to your social media accounts", //social media accounts",
    collabManagementDesktop: "Simplify collabs with our intuitive portal",
    collabManagementMobile: "Simplify collaborations with our intuitive portal",
    collabControl: "Create & edit collab options in minutes, and easily manage collabs from start to finish",
    // influencerSearch: "Discover and network with other influencers you'd like to collaborate with",
    // influencerCollaboration: "Utilize our search tool and share your resume link to create new opportunities for growth",// and growth",
    seeCollaborationDemo: "See Collab Demo",
    //BRAND 
    brandName: "Lynscy",
    brandOfficialName: "Lyncsy International Ltd",
    //LOGIN PAGE
    signIn: "Sign In",
    login: "Login",
    logout: "Logout",
    continueWith: "Continue With",
    OR: "OR",
    email: "Email",
    password: "Password",
    forgotPassword: "Forgot Password",
    rememberMe: "Remember me",
    continueWithFacebook: "Continue with Facebook",
    continueWithGoogle: "Continue with Google",
    socialLogin: "Sign in with your social networks",
    socialSignUp: "Sign Up with your social networks",
    noAccount: "Don't have an account? Create one",
    //SIGN UP 
    registerWith: "Register With",
    join: "Join",
    joinNow: "Join over 5000+ influencers and boost your online presense now",
    signUp: "Sign Up",
    confirmPassword: "Confirm Password",
    agreeTo: "I agree to the",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    alreadyHaveAccount: "Already have an account? Log in",
    //RESUME OVERVIEW
    here: "here",
    followers: "Followers",
    contact: "Contact",
    genre: "Genre",
    demographics: "Demographics",
    about: "About",
    there: "there",
    emailSubject: "Exciting Colloboration Opportunity! - Lyncsy",
    emailBody: emailBody,
    //PROFILE SIDE CARD
    profile: "Profile",
    firstName: "Firstname",
    lastName: "Lastname",
    defaultNameText: "Firstname Lastname",
    jobTitle: "Job Title",
    previewProfile: "Preview Profile",
    profileInfo: "Profile Information",
    settings: "Settings",
    socialMediaAccounts: "Social Media Accounts",
    youtubeAccount: "YouTube Account",
    instagramAccount: "Instagram Account",
    tiktokAccount: "TikTok Account",
    twitchAccount: "Twitch Account",
    //PROFILE INFO
    gender: "Gender",
    male: "Male",
    female: "Female",
    pronouns: "Pronouns",
    age: "Age",
    languages: "Languages",
    generalInfo: "General",
    contactInfo: "Contact",
    primaryPhoneNumber: "Primary Phone Number",
    secondaryPhoneNumber: "Secondary Phone Number",
    addressInfo: "Address",
    country: "Country",
    city: "City",
    province: "Province",
    state: "State",
    primaryAddress: "Primary Address",
    secondaryAddress: "Secondary Address",
    postalCode: "Postal Code",
    zipCode: "Zip Code",
    linksInfo: "Links",
    website: "Website",
    socialLinks: "Social Links",
    customUrl: "Custom URL",
    verified: "verified",
    //COLLABS
    collabs: "Collabs",
    collab: "Collab",
    startCollab: "Start Collab",
    addCollab: "Add Collab",
    newCollab: "New Collab",
    newCollabRequest: "New Collab Request",
    editCollab: "Edit Collab",
    deleteCollab: "Delete Collab",
    deleteCollabConfirmation: "You are about to delete a collab. Are you sure you want to proceed?",
    //COLLAB MODAL
    title: "Title",
    price: "Price",
    deliveryTime: "Delivery Time",
    socialMediaType: "Social Media Type",
    collabType: "Collab Type",
    collabImage: "Collab Image",
    //EXPERIENCE
    experience: "Experience",
    newExperience: "New Experience",
    editExperience: "Edit Experience",
    employmentType: "Employment Type",
    companyName: "Company Name",
    locationType: "Location Type",
    startDate: "Start Date",
    endDate: "End Date",
    industry: "Industry",
    description: "Description",
    skills: "skills",
    mediaContents: "Media Contents",
    create: "Create",
    submit: "Submit",
    save: "Save",
    new: "New",
    cancel: "Cancel",
    back: "Back",
    currrentRole: "I am currently working in this role",
    //SOCIAL MEDIA ACCOUNT - PROFILE EDIT
    accountInfo: "Account Information",
    accountLink: "Account Link",
    accountUsername: "Account Username",
    accountEmail: "Account Email",
    pinPosts: "Pinned Posts",
    accountPosts: "Account Posts",
    pinnedPostLink1: "Post Link 1",
    pinnedPostLink2: "Post Link 2",
    pinnedPostLink3: "Post Link 3",
    primaryAccount: "Primary Account",
    disabledAccount: "Disable Account",
    accountPostFilter: "Filter Posts By",
    accountMetricSelections: "Metric Options (choose 3 metrics)",
    accountMetricLineSelections: "Metric Line Chart Options",
    filterSetting: "Filter Setting",
    generalSetting: "General Setting",
    default: "default",
    //MEDIA POSTS
    postDetails: "Post Details",
    connectAccount: connectAccount,
    //SERVER ERROR / PAGE NOT FOUND
    oops: "Oops!",
    pageNotFound: "Sorry, page not found!",
    goToHome: "Go to Home",
    goToProfile: "Go to Profile",
    internalServerError: "Internal server error",
    goToHomeConfirmation: "You will be logged out if you go back to the home page. Are you sure you want to proceed?",
    //NOT FOUND
    notFound: "Sorry, no data found. Please create data first",
    userInfoNotFound: "Sorry no user information was found. Please try refereshing the page",
    madatoryStepsNotCompleted: "Please finish setting up Profile Information and at least one Social Media Account first",
    noAccountPostsFound: "No account posts were found for this platform",
    noAccountAnalyticsfound: "No account analytics were found for this platform",
    noCollabsFound: "No collabs were found",
    //SOCIAL MEDIA OVERVIEW
    last365Days: '(Last 365 days)',
    last90Days: '(Last 90 days)',
    breakdowns: 'Breakdowns',
    upTo365Days: '(Up to 365 days)',
    //SOCIAL MEDIA ANALYTICS
    analyticType: "(Analytic type)",
    //SOCIAL MEDIA POSTS
    insights: "Insights",
    posts: "Posts",
    viewAll: "View All",
    lifeTimeMetrics: "(Lifetime metrics)",
    //SOCIAL MEDIA METRICS
    followersGained: "Followers Gained",
    averageViewDuration: "Avg. View Duration",
    views: "Views",
    likes: "Likes",
    comments: "Comments",
    shares: "Shares",
    annotationClickThroughRate: 'Annotation Click Through Rate',
    duration: 'Duration',
    engagementRate: "Eng. Rate Overview",
    yearlyMetricsOverview: "Yearly Metrics Overview",
    monthlyMetricsOverview: "Monthly Metrics Overview",
    userEngagementOverview: "User Engagement Overview",
    audienceOverview: "Audience Overview",
    impressions: "Impressions",
    reach: "Reach",
    //SOCIAL MEDIA AUDIENCE
    topAge: "Top Age",
    topGender: "Top Gender",
    topCountries: "Top 10 Countries",
    topProvinces: "Top 10 Provinces",
    topCities: "Top 10 Cities",
    countries: "Countries",
    provinces: "Provinces",
    citites: "Citites",
    audienceSummary: "Audience",
    //SETTINGS
    deleteAccountConfirmation: "You are about to delete your account. This will permanently remove your account information and cancel your subscriptions. Are you sure you want to proceed?",
    deleteAccount: "Delete Account",
    //SUBCRIPTION
    subscriptions: "Subscriptions",
    manage: "Manage",
    subscribed: "Subscribed",
    allPlatform: "All Platform",
    notSubscribed: "Not Subscribed",
    subcriptionSubtitle: "Enable Analytics Now!",//"Boost your online presence and stand out from the crowd to attract more opportunities and success",
    subscriptionDescription: 'Subscribe now to access all features with full analytics and secure more brand deals!',
    getAnalytics: "Enable Analytics",
    alreadySubscribed: "Already Subscribed",
    hasAnalyticAccess: "Analytics access is enabled for this account",
    noAnalyticAccess: "Access to analytics is not enabled for this account",
    startFreeTrial: "Start Free Trial",
    get30DaysFreeTrial: '30 Days FREE Trial!',
    freeTrialDescription: 'Try all features with full analytics. Cancel anytime, hassle-free!',
    //SUBCRIPTION MODAL
    free: 'Free',
    perMonth: ' / month',
    perYear: ' / year',
    youtubeAnalytics: 'YouTube Analytics',
    instagramAnalytics: 'Instagram Analytics',
    tiktokAnalytics: 'TikTok Analytics',
    analyticsPayment: "Unlock Advanced Insights",
    subscriptionPrice: "$4.99 CAD One-time Fee!",
    sellingPoint: "Impress Recruiters with Detailed Analytics",
    accessToAnalytics: "Comprehensive Analytics for all Platforms",
    customizableAnalytics: "Tailor Analytics to Showcase Your Strengths",
    professionalEdge: " Showcase impressive analytics to boost your online presence",
    standOut: "Boost your Online Presence with analytics",
    impressBrands: "Impress Recruiters with Reach & Impact Metrics",
    personalBranding: "Elevate your Personal Brand through Analytics",
    //SUBCRIPTION PRODUCT
    addSubscription: "Add Subscription",
    //PASSWARD FORM
    confirm: "Confirm",
    changePassword: "Change Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    //PAYMENT RESULT 
    successResult: "Success Result",
    analyticsPurchaseSuccess: "Successfully Purchased Analytics!",
    updateProfileInfo: "Please make sure to finish updating profile to see analytics",
    //PRICING
    findPricingPlan: "Find a plan to empower your Influencer Career",
    pricePlanCoverage: "Just starting out or already thriving, our app supports influencers at every stage",
    freePlan: "Free Plan",
    freePlanDescription: "A single hub to showcase all your media contents, without needing multiple links",
    analyticslan: "Analytics Plan",
    analyticsPlanDescription: "Back your accounts with comprehensive overview, metrics and audience analytics",
    //NIGHT MODE SETTINGS
    changeTheme: "Change Theme",
    //CLIPBOARD INPUT
    copied: "copied",
    //FOOTER
    quickLinks: "Quick Links",
    demo: "Demo",
    support: "Support",
    donation: "Donation",
    feedback: "Feedback",
    mention: "Mention",
    FAQ: "FAQ",
    careers: "Careers",
    legal: "Legal",
    termsOfService: "Terms of Service",
    //RESUME TABS
    platforms: "Platforms",
    //APPLICATIONS MODAL
    additionalInfo: "Add any additional requests / comments: ",
    status: "Status",
    autoStartContract: "Auto start collab",
    requestorApproval: "Requestor Approval",
    applicationEdit: "Application Edit",
    //CONTRACT MODAL
    contractEdit: "Contract Edit",
};

export const PageTitles = {
    Home: "All-in-One Influencer Resume and Portfolio",
    InfluencerProfile: "Influencer Profile",
    ProfileInfo: "Profile Information",
    SocialMediaAccounts: "Social Media Accounts",
    Experience: "Experience",
    Collabs: "Collabs",
    Login: "Login",
    Signup: "Sign Up",
    ServerError: "Server Error",
    PageNotFound: "Page Not Found",
    Settings: "Settings",
    PaymentResult: "Payment Result",
    Pricing: "Pricing",
    PrivacyPolicy: "Privacy Policy",
    TermsOfService: "Terms Of Service",
    FAQ: "Frequently Asked Questions"
};

export const PageDescriptions = {
    Home: "Manage your social media profiles effectively with Lyncsy. Centralize all your content, gain insights through detailed analytics, and increase engagement across your social media accounts. Cut down on costs and time spent managing portfolio sites and paying agent commissions.",
};


export const validations = {
    requiredField: "This field is required",
    notValidEmail: "Please input a valid email address",
    notValidPhoneNumber: "Please input a valid phone number",
    notValidPostalCode: "Please input a valid postal code",
    notValidWebsite: "Please input a valid website",
    notValidCustomUrl: "Custom URL must contain 3-100 letters or numbers. Please do not use spaces, symbols, or special characters",
    notValidPassword: "Password should be minimum eight characters and contain at least one uppercase letter, one lowercase letter and one number",
    wrongEmailorPassword: "Wrong email or password",
    lettersOnly: "Please enter letters only",
    startDateBeforeEndDate: "Start date must be before end date",
    emptyStartDate: "Start date must be selected",
    passwordMismatch: "The two passwords provided do not match",
    samePassword: "New password can't be same as previous password",
    mustCheckTermsAndPolicy: "You must agree to the terms of service and privacy policy"
};

export const notification = {
    messages: {
        success: "Success",
        saveSuccess: "Successfully saved!",
        loginSuccess: "Login was successful!",
        signUpSuccess: "Sign up was successful!",
        updateSuccess: "Successfully updated information!",
        requestSuccess: "Request send successfully!",
        deleteSuccess: "Successfully deleted!",
        updateImageSuccess: "Image was ulpoaded successfully!",
        commonError: "Error!",
        loadingInfluencerError: "Error loading influencer",
        updateCollabError: "Error creating / updating Collab",
        updateImageError: "Error uploading image",
        updatePostVisibilityError: "Error updating post visibility",
        completeMandatorySteps: "Please set up Profile Info and at least one Social Media Account",
        noCustomUrl: "Please set up your custom url from Profile Info",
        noAnalytics: "No Analytics was found",
        notSignedIn: "You are not signed in. Please sign in first",
        userSignedIn: "User was signed in already, redirecting to Profile"
    },
    headers: {
        loginAttempt: "There was an attempt to log in to your account from another device",
        mention: "mentioned you in <0>{{place}}</0>",
        noNotifications: "No notifications yet",
        readAll: "Mark all as read",
        viewAll: "View all",
        serverError: "The server is not responding, try again later",
        successPayment: "Your payment was successfully made",
        types: {
            email: 'Email',
            push: 'Push',
            SMS: 'SMS',
            activities: 'Activities',
        }
    }
};

const signUpSubTitle = "Please ensure that you meet all the requirements:"

export const signUpInstructions = {
    YouTube: {
        title: "Google & YouTube",
        description: "Connect your YouTube account with Lyncsy to create your influencer resume!",
        subTitle: signUpSubTitle,
        instructions: [
            {
                title: "A YouTube Account that is connected to your selected Gmail.",
                estimatedTime: "(1 minute)",
                link: "https://support.google.com/youtube/answer/161805?hl=enx&co=GENIE.Platform%3DDesktop&oco=1#",
                linkText: "Create an account on YouTube",
            }
        ],
        limitationText: "Demographic analytics will be limited if an account doesn't have enough views. \n Please ensure you accept all the services in order to proceed.",
        button: {
            title: "Continue with Google",
            textColor: "#000000",
            backgroundColor: "#FFFFFF"
        },
    },
    Instagram: {
        title: "Facebook & Instragram",
        description: "Connect your Instagram account with Lyncsy to create your influencer resume!",
        subTitle: signUpSubTitle,
        instructions: [
            {
                title: "A published Facebook Page (this is different than your Facebook profile).",
                estimatedTime: "(2 minutes)",
                link: "https://www.facebook.com/business/help/1199464373557428?id=418112142508425",
                linkText: "How to create a Facebook Page",
            },
            {
                title: "A professional account on Instagram.",
                estimatedTime: "(1 minute)",
                link: "https://help.instagram.com/2358103564437429",
                linkText: "How to set up a creator account on Instagram"
            },
            {
                title: "Link your Facebook Page and Instagram professional account.",
                estimatedTime: "(1 minute)",
                link: "https://www.facebook.com/business/help/1125825714110549?id=420299598837059&helpref=faq_content",
                linkText: "Add an Instagram account to your business portfolio"
            },
        ],
        limitationText: "Analytics will show data for only insights collected after switching to professional account.",
        button: {
            title: "Continue with Facebook",
            textColor: "#FFFFFF",
            backgroundColor: "#3c5a9a"
        },
    },
    TikTok: {
        title: "TikTok",
        description: "Connect your TikTok account with Lyncsy to create your influencer resume!",
        subTitle: signUpSubTitle,
        instructions: [
            {
                title: "A Public TikTok Account.",
                estimatedTime: "(1 minute)",
                link: "https://www.tiktok.com/signup",
                linkText: "Sign up for TikTok"
            },
        ],
        limitationText: "",
        button: {
            title: "Connect with TikTok",
            textColor: "#000000",
            backgroundColor: "#FFFFFF"
        },
    }
};

export const faq = {
    questions: {
        isItSecure: "Is Lyncsy secure to use?",
        whyRequestAccountInfo: "Why does Lyncsy request account info during sign up?",
        whoUseIt: "Who should use Lyncsy?",
        whyUseIt: "Why I should use Lynscy?",
        howCollabWorks: "How does collabs work?",
        howToEnsureCollab: "How does Lyncsy ensure collab success?",
        isItFree: "Is Lyncsy free to use?",
    },
    answers:{
        isItSecure: "Lyncsy is secure to use because we only gather information of your accounts from publicly available APIs provided by social media platforms. \n We only request read-only access scopes, ensuring no modifications are made to your account. Additionally, we encrypt the data stored in our database to keep your account secure.",
        whyRequestAccountInfo: "In order to display user's social media account posts and display their analytics, Lyncsy asks for required information of your accounts. \n We only request read-only access scopes, ensuring no modifications are made to your account.",
        whoUseIt: "Any social media users. Whether you're just starting out or already established, Lyncsy helps you grow your audience while getting paid from collaborations.",
        whyUseIt: "As a creator, your primary goal is to grow and monetize your influence. With Lyncsy's collab feature, you can achieve both—by collaborating with larger influencers to expand your audience or earning by helping smaller influencers as an established creator.",
        howCollabWorks: "Simply create and list your collab offers and wait for others to reach out, or send requests to other creators. Manage the entire process via our portal and get paid once your content is delivered and collab is completed.",
        howToEnsureCollab: "Approval from both the requestor and receiver is required to initiate a collab, ensuring that both parties agree on collab details. After starting the collab, final approval from both is necessary to complete the contract, with payment issued to the receiver and the requestor's commitment fulfilled. Any breach of these agreements will result in a ban from the platform, prohibiting future use of the app. For any issues or to provide evidence of the breach, please contact us at",
        isItFree: "Yes, Lyncsy is entirely free with our basic plan. You can choose to subscribe to access advanced analytics, which can enhance your credibility and help attract more collaborations. However, this subscription is optional and not required to use the platform.",
    },
    contactEmail: "contact.lyncsy@gmail.com",
};

export const legal = {
    privacyPolicy: {
        titles: {
            informationCollectionAndUsage: "Information Collection And Usage",
            purchases: "Purchases",
            linksToOtherWebsite: "Links To Other Websites",
            disclosureOfPersonalData: "Disclosure Of Your Personal Data",
            securityOfYourPersonalData: "Security Of Your Personal Data",
            childrensPrivacy: "Children's Privacy",
            dataRetention: "Data Retention",
            userRights: "User Rights",
            cookiesAndTrackingTech: "Cookies and Tracking Technologies",
            internationalDataTransfers: "International Data Transfers",
            changesToThisPrivacyPolicy: "Changes To This Privacy Policy",
            contactUs: "Contact Us",
        },
        subtitles: {
            howWeCollectInfo: "How We Collect Your Information",
            personalInfo: "1. Personal Information: ",
            socialAccountInfo: "2. Social Account Information: ",
            userProvidedInfo: "3. User-Provided Information: ",
            howWeUseInfo: "How We Use Your Information",
            inProductPrivacyNotifications: "In-Product Privacy Notifications",
            limitedUse: "Limited Use",
            informationSharing: "Information Sharing",
            businessTransactions: "Business Transactions",
            lawEnforcement: "Law Enforcement",
            prohibitedUseOfUserData: "Prohibited Transfers, Uses, or Sales of User Data",
            otherLegalRequirements: "Other Legal Requirements",
        },
        contexts: {
            updatedDate: "Last Updated April 1, 2024",
            privacyPolicyAbout: "This Privacy Policy describes how Lyncsy ('Company,' 'we,' 'us,' and 'our') collect, use, and disclose information when you use our service and outlines your privacy rights and protections under the law.",
            informationCollection: "When you use our service, we may collect certain personal information from you, including:",
            personalInfo: "When you sign in using third-party login, we collect your basic public profile information from the third-party login provider, including your name, email address, profile picture, and any other information you choose to make publicly available.",
            socialAccountInfo: "After signing in with third-party login, we may connect to your social media account to access your account information and analytics reports. This includes data such as your account name, description, follower count, post views, and other analytics data.",
            userProvidedInfo: "We may collect additional information provided by you, such as your city, address, country, phone number, email address, and any other information you choose to provide while using the Service.",
            informationUsage: "We may use the information we collect for various purposes, including to:\n\n• Provide, maintain, and improve the Service.\n• Personalize your experience and provide customized content.\n• Communicate with you about the Service, including updates and promotions.\n• Analyze usage trends and improve the Service's functionality.\n• Comply with legal requirements and protect our rights.",
            inProductPrivacy: "In-product privacy notifications are prominently displayed within our app interface so that users can easily find information about how their social media user data is accessed and used.",
            purchases: "Stripe processes purchases made through the Website. Stripe may ask you for personal and/or non-personal information, such as your name, address, email address, credit card information, or other Personal Information. Stripe has a privacy policy that describes their collection and use of personal information. We do not control Stripe or its collection or use of information. Any questions or concerns about Stripe's practices should be directed to Stripe.\n\nStripe provides us with certain non-personal information relating to purchases made by visitors to the Website. The non-personal information may include details of the purchase such as the date, amount paid, and product purchased. The non-personal purchase information may be linked to the Personal Information you provide to us (typically limited to your email address, as stated above). Stripe does not supply us with any of your other Personal Information such as your name, street address, or credit card information. Refer to Stripe's privacy policy for more information ",
            stripePrivacyLink: "https://stripe.com/privacy",
            otherWebsites: "Our website may contain links to other websites that are not owned or controlled by us. We are not responsible for the privacy practices of these websites or third parties. We encourage you to review the privacy statements of any website that collects personal information.",
            limitedUse: "We limit our use of user data from social media platforms to the practices disclosed in this Privacy Policy and in accordance with the respective platforms' terms and policies.\n\n Please refer to the respective platforms' limited use policies:",
            informationSharing: "We do not share user data from social media platforms with third parties, except as required for the operation of our web app or as otherwise described in this Privacy Policy.",
            businessTransaction: "If we are involved in a merger, acquisition, or asset sale, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a different privacy policy.",
            prohibitedUseOfUserData: "We are committed to protecting your privacy. As such, all other transfers, uses, or sales of user data are strictly prohibited, including:\n\n• Transferring or selling your data to third parties such as advertising platforms, data brokers, or any information resellers.\n• Using your data for serving ads, including retargeting, personalized, or interest-based advertising.\n• Using your data to determine credit-worthiness or for lending purposes.\n\n We ensure that our employees, agents, contractors, and successors comply with this policy to safeguard your information.",
            lawEnforcement: "Under certain circumstances, we may be required to disclose your personal data if required by law or in response to valid requests by public authorities.",
            otherLegalRequirements: "We may disclose your personal data in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property, prevent or investigate possible wrongdoing, or protect the personal safety of users or the public.",
            pesonalDataSecurity: "While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security. No method of transmission over the internet or electronic storage is 100% secure.",
            childrensPrivacy: "Our service is not intended for anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under 13 years of age. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to remove that information from our servers.",
            dataRetention: "We retain personal data for as long as necessary to provide the services you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements.",
            userRights: "You have the right to access, correct, or delete your personal data. You may also have the right to object to or restrict certain types of processing of your personal data.",
            cookiesAndTrackingTech: "Our website uses cookies and other tracking technologies to provide and improve our services. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.",
            internationalDataTransfer: "Personal data collected by us may be stored and processed in your region, or in any other country where we or our service providers are located. By using our services, you consent to the transfer of your information to countries outside of your country of residence, which may have different data protection rules than those of your country.",
            changesToPrivacyPolicy: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
            contactUs: "If you have any questions about this Privacy Policy, you can contact us at ",
            contactEmail: "contact.lyncsy@gmail.com",//"privacy@lyncsy.com",
        },
        socialPlatformsLimitedUsePolicy: {
            "Google API Services User Data Policy": "https://developers.google.com/terms/api-services-user-data-policy",
        },
    },
    termsOfService: {
        titles: {
            acceptanceOfTerms: "1. Acceptance of Terms",
            privacyPolicy: "2. Privacy Policy",
            userAccounts: "3. User Accounts",
            userContent: "4. User Content",
            thirdPartyAccounts: "5. Third-Party Accounts",
            useOfInfo: "6. Use of Information",
            prohibitedActivities: "7. Prohibited Activities",
            intellectualProperty: "8. Intellectual Property",
            purchases: "9. Purchases",
            stripeIntegration: "10. Stripe Integration",
            refundPolicy: "11. Refund Policy",
            limitationOfLiability: "12. Limitation of Liability",
            governingLaw: "13. Governing Law",
            socialAPIs: "14. Social APIs",
            changesToTerms: "15. Changes to Terms",
            contactUs: "16. Contact Us",
        },
        subtitles: {
            refundEligibility: "Refund Eligibility",
            refundProcess: "Refund Process",
            annualSubscription: "Annual Subscription",
            monthlySubscription: "Monthly Subscription",
            donation: "Donation",
            socialPlatformsTermsOfServices: "Social Platforms Terms Of Services",
            socialPlatformsPrivacyPolicies: "Social Platforms Privacy Policies",
        },
        contexts: {
            updatedDate: "Last Updated April 1, 2024",
            termsOfServiceAbout: "These Terms of Service ('Terms') govern your access to and use of the Lyncsy service ('Service') provided by Lyncsy ('Company,' 'we,' 'us,' or 'our'). By accessing or using the Service, you agree to be bound by these Terms.",
            acceptanceOfTerms: "By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.",
            privacyPolicy: "Your use of the Service is also subject to our Privacy Policy, which governs our collection, use, and disclosure of your information. By using the Service, you consent to our Privacy Policy and agree to its terms.",
            userAccounts: "To access certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
            userContent: "You are solely responsible for any content that you upload, submit, or otherwise make available through the Service ('User Content'). By uploading, submitting, or making User Content available, you represent and warrant that you have all necessary rights to do so and that your User Content does not violate these Terms or any applicable laws or regulations.",
            thirdPartyAccounts: "The Service may allow you to connect your social media accounts, such as YouTube, Instagram, TikTok and Twitch, to your Lyncsy account. By connecting your social media accounts, you authorize us to access and collect your basic public profile information, social media account information, and analytics for your social media account contents and accounts.",
            useOfInfo: "We may use the information collected from your social media accounts, as well as any user-provided information such as city, address, country, phone number, and email, to provide and improve the Service, personalize your experience, communicate with you, analyze usage trends, and comply with legal requirements.",
            prohibitedActivities: "You agree not to engage in any prohibited activities while using the Service, including but not limited to:\n \n• Violating any laws or regulations.\n• Uploading or sharing any content that is unlawful, defamatory, obscene, or infringes on the rights of others.\n• Accessing or using the Service in any manner that could damage, disable, overburden, or impair the Service or interfere with any other party's use and enjoyment of the Service.",
            intellectualProperty: "All content and materials available on the Service, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of Lyncsy or its licensors and are protected by copyright, trademark, and other intellectual property laws.",
            purchases: "Some features of the Service may require payment. If you wish to purchase these features, you will be asked to provide certain information relevant to your purchase, including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.\nYou represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any purchase; and that (ii) the information you supply to us is true, correct, and complete.\nBy submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of purchases.",
            stripeIntegration: "We use Stripe for processing payments. By using the Service, you agree to be bound by Stripe's Terms of Service ",
            stripeTermsOfServiceLink: "https://stripe.com/terms",
            stripePrivacyPolicyLink: "https://stripe.com/privacy",
            refundPolicyAbout: "We aim for complete customer satisfaction with every purchase made through our Service. If you are not entirely satisfied with your purchase, we're here to assist you.",
            annualSubscriptionRefund: "Refunds are available within 30 days of the transaction date. Each customer is eligible for a refund on their first annual plan purchase only. Subsequent annual plan purchases by the same customer are not eligible for refunds, unless due to service unavailability or other critical reasons.",
            monthlySubscriptionRefund: "Refunds are available within 24 hours of the transaction date.",
            donationRefund: "Refunds for donations will be considered under the following conditions: \n1. Error in Donation Amount: If you made a mistake in the donation amount. \n2. Unauthorized Transaction: If you did not authorize the transaction. \n3. Duplicate Donation: If you accidentally donated more than once.",
            refundEligibilityAbout: "To request a refund, please provide proof of purchase and a detailed explanation for your refund request.",
            refundContact: "To request a refund, please provide the following details and contact us at ",
            refundProcess: "\n• Full name \n• Purchase / Donation date \n• Refund request amount \n• Reason for the refund request \n• Screenshot proof showing the purchase date, purchase amount, and justification of the reason for the refund \n\nUpon receipt and review of your refund request, we will notify you of the approval or rejection within reasonable timeframe. If approved, your refund will be processed promptly, and a credit will automatically be applied to your credit card or original method of payment within a reasonable timeframe. \n\nWe reserve the right to deny refund requests if the terms of this policy are not met or if the request is deemed to be in bad faith. Examples of bad faith include but are not limited to misuse of the service, repeated refund requests, or attempts to exploit the refund policy.",
            limitationOfLiability: "To the fullest extent permitted by applicable law, in no event shall Lyncsy be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with your use of or inability to use the Service, including any purchases made through the Service.",
            governingLaw: "These Terms shall be governed by and construed in accordance with the laws of Canada, without regard to its conflict of law principles.",
            socialAPIsAbout: "Lyncsy uses authorized data from Instagram, YouTube, Google, TikTok, Twitch, Facebook, and other platforms (“Social Platforms”). Such data is collected and stored to power Lyncsy features. For example, Lyncsy uses YouTube, Google, Facebook, TikTok, Twitch, Instagram, and other Social Platforms’ APIs to collect authorized data. By using Lyncsy services, you agree to be bound by each respective Social Platform’s terms of service and privacy policies available via the links below:",
            socialAPIsSecuritySettingAbout: "You can revoke Lyncsy's access to your data from YouTube, Google, Facebook, Instagram, TikTok and Twitch at any time via each respective platform’s app and security settings via the links below. Note that revoking such authorizations may limit or eliminate certain functionality in Lyncsy's Products.",
            socialAPIsContact: "If you have any questions about how your data is used or if you would like to request the deletion of your authorized data from Lyncsy, please contact us at ",
            changesToTerms: "We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
            contactUs: "If you have any questions about these Terms, please contact us at ",
            contactEmail: "contact.lyncsy@gmail.com",//"termsOfService@lyncsy.com",
        },
        socialPlatformsTermsOfService: {
            Google: "https://www.google.com/intl/en/policies/terms/",
            YouTube: "https://www.youtube.com/t/terms , https://myaccount.google.com/permissions",
            "YouTube API Client": "https://developers.google.com/youtube/terms/api-services-terms-of-service",
            Facebook: "https://www.facebook.com/legal/terms",
            Instagram: "https://help.instagram.com/478745558852511",
            TikTok: "https://www.tiktok.com/legal/terms-of-service",
            Twitch: "https://www.twitch.tv/p/legal/terms-of-service"
        },
        socialPlatformsPrivacyPolicies: {
            "Google And YouTube": "http://www.google.com/policies/privacy",
            Facebook: "https://www.facebook.com/privacy/explanation",
            Instagram: "https://help.instagram.com/155833707900388",
            TikTok: "https://www.tiktok.com/legal/privacy-policy",
            Twitch: "https://www.twitch.tv/p/legal/privacy-policy"
        },
        socialPlatformsSecuritySettings: {
            "Google And YouTube": "https://security.google.com/settings/security/permissions",
            Facebook: "https://www.facebook.com/settings?tab=applications",
            Instagram: "https://www.instagram.com/accounts/manage_access/",
            TikTok: "https://www.tiktok.com/settings",
            Twitch: "https://www.twitch.tv/settings/security"
        },
    },
}

export const tooltipInfos = {
    copy: "copy",
    openInNewTab: "Open in new tab",
    primaryAccountInfo: "Primary Account will be shown first in your profile and will be marked as 'Primary'",
    disabledAccountInfo: "Disabled Account will not be shown in your profile",
    hidePosts: "Click on the icon to hide a post. Hidden posts will not be displayed in the resume",
    showPosts: "Click on the icon to show the post. Shown posts will be displayed in the resume",
    filterPostInfo: "Customize the order of how the posts will be shown in the your profile",
    metricSelectionsInfo: "Customize up to 3 statistic metrics to display on your profile's analytics page",
    metricLineSelectionsInfo: "Customize which metrics appear on your profile's metric line chart in the analytics page",
    customUrlInfo: "Custom URL is a personalized URL for your profile. Recommendation: Use your firstname and lastname. Ex: john-doe",
    incompleteProfileProgress: "Following steps are pending: \n",
    completeProfileProgress: "All steps are completed",
    autoStartContract: "Once approved by the receiver (the collaborator you're requesting), the contract will auto-start, allowing the collaboration to begin right away",
    finishContract: "When the requestor approves, the contract will be finalized, and revision requests will no longer be accepted",
    applicationNotEditable: "You cannot edit this application since it has already been approved by both parties",
    pendingRequestorApproval: "Awaiting requestor's approval to begin the collaboration. The receiver has already approved",
    pendingRequestorApprovalForContract: "Awaiting requestor's approval to finish the collaboration. The receiver has already completed or canceled the contract",
    contractNotEditable: "You cannot edit this contract since it has already been completed or canceled and approved by both parties",
    statistics: {
        //SOCIAL MEDIA OVERVIEW
        platformGenderComparison: "Gender distribution comparison across platforms (last 90 days)",
        platformAgeComparison: "Age group distribution comparison across platforms (last 90 days)",
        platformCountryComparison: "Top 5 countries comparison for each platform (last 90 days)",
        platformCityComparison: "Top 5 cities comparison for each platform (last 90 days)",
        //SOCIAL MEDIA POST
        totalLikes: "Total likes received across all posts",
        totalShares: "Total shares received across all posts",
        totalComments: "Total comments received across all posts",
        totalFollowers: "Total followers on the selected platform",
        totalFollows: "Total followers on by the influencer on the selected platform",
        totalViews: "Total views received across all posts",
        totalPostCount: "Total number of posts made on the selected platform",
        //SOCIAL MEDIA ANALYTIC
        totalFollowersStatistic: "Total followers count compared to followers from all social media platforms",
        totalPostsStatistic: "Total posts count compared to posts from all social media platforms",
        yearlyEngRateStatistic: "Engagement rate calculated as (likes + shares + comments) / total views for each month over the last 12 months",
        yearlyViewsStatistic: "Views calculated for each month over the last 12 months",
        yearlyLikesStatistic: "Likes calculated for each month over the last 12 months",
        yearlySharesStatistic: "Shares calculated for each month over the last 12 months",
        yearlyCommentsStatistic: "Comments calculated for each month over the last 12 months",
        yearlyAvgViewDurationStatistic: "Average view duration calculated for each month over the last 12 months",
        yearlyDurationStatistic: "Duration calculated for each month over the last 12 months",
        yearlyAnnotationClickThroughRateStatistic: "Annotation Click Through Rate calculated for each month over the last 12 months",
        yearlyFollowersGainedStatistic: "New followers gained for each month over the last 12 months",
        yearlyReachStatistic: "Reach (Number of unique accounts that have seen your posts, stories, reels, videos and live videos) calculated for each month over the last 12 months",
        yearlyImpressionStatistic: "Impressions (Number of times your posts, stories, reels, videos and live videos were on screen. Include multiple views of your content by the same accounts) calculated for each month over the last 12 months",
        yearlyMetricOverview: "Overview of metrics for each month over the last 12 months",
        userEngagementOverview: "Overview of user engagement (likes, shares, comments) over the last 12 months",
        likesPerVideoStatistic: "Likes calculated for each video",
        viewsPerVideoStatistic: "Views calculated for each video",
        commentsPerVideoStatistic: "Comments calculated for each video",
        engRatePerVideoStatistic: "Engagement rate calculated as (likes + shares + comments) / total views for each video",
        //SOCIAL MEDIA AUDIENCE
        genderStatistic: "Top gender characteristics of platform audience",
        ageStatistic: "Top age characteristics of platform audience",
        genderAndAgeStatistic: "Top gender and age characteristics of platform audience",
        countryStatistic: "Top country characteristics of platform audience",
        provinceStatistic: "Top province characteristics of platform audience",
        cityStatistic: "Top city characteristics of platform audience",
        topCountriesStatistic: "Top 10 country characteristics of platform audience",
        topProvincesStatistic: "Top 10 province characteristics of platform audience",
        topCitiesStatistic: "Top 10 city characteristics of platform audience",
    }
};

export const error404 = {
    comeBack: "Return to Home",
    notFound: "Sorry, page not found!"
};

export const colors = {
    primary: "primary",
    secondary: "secondary",
    error: "error",
    success: "success",
    warning: "warning",
    default: "default",
    textLight: "text-light",
    tagBackground: "tag-background",
    white: "white"
};

export const chart = {
    chartColors: {
        blue: "blue",
        aqua: "aqua",
        green: "green",
        purple: "purple",
        lavendar: "lavendar",
        cyan: "cyan",
        all: "all"
    },
    chartColorIntensity: {
        full: "full",
        strong: "strong",
        medium: "medium",
        light: "light"
    }
}

export const postFilters: { [key: string]: string[] } = {
    YouTube: [
        "Most Recent",
        "Most Viewed",
        "Most Liked",
        "Most Commented",
    ],
    Instagram: [
        "Most Recent",
        "Most Liked",
        "Most Commented"
    ],
    TikTok: [
        "Most Recent",
        "Most Viewed",
        "Most Liked",
        "Most Commented",
        "Most Shared",
    ]
};

export const defaultPostFilter = 'Most Recent';

export const metricSelections: { [key: string]: string[] } = {
    YouTube: [
        'views',
        'averageViewDuration',
        'subscribersGained',
        'shares',
        'likes',
        'comments',
        'annotationClickThroughRate'
    ],
    Instagram: [
        'impressions',
        'reach',
        'shares',
        'likes',
        'comments',
        'followersGained'
    ],
    //TODO: check if you need duration
    TikTok: [
        'views',
        'shares',
        'likes',
        'comments',
        'duration'
    ],
};

export const defaultMetricSelections: { [key: string]: string[] } = {
    YouTube: [
        'views',
        'averageViewDuration',
        'subscribersGained',
    ],
    Instagram: [
        'impressions',
        'reach',
        'likes',
    ],
    //TODO: check if you need duration
    TikTok: [
        'views',
        'shares',
        'likes',
    ],
};

export const overviewMetrics = {
    followers: 'Followers',
    views: 'Views',
    engRate: 'Eng. Rate',
};

export const overviewSecondaryMetrics = {
    views: 'Views',
    likes: 'Likes',
    comments: 'Comments',
    shares: 'Shares'
};

export const overviewComparisonMetrics = {
    followers: 'Followers',
    engRate: 'Eng. Rate',
};


export const metricDisplayInfo: { [key: string]: { [key: string]: { label: string; tooltipInfo: string } } } = {
    YouTube: {
        views: {
            label: 'Views',
            tooltipInfo: tooltipInfos.statistics.yearlyViewsStatistic
        },
        averageViewDuration: {
            label: 'Average View Duration',
            tooltipInfo: tooltipInfos.statistics.yearlyAvgViewDurationStatistic
        },
        subscribersGained: {
            label: 'Subscribers Gained',
            tooltipInfo: tooltipInfos.statistics.yearlyFollowersGainedStatistic
        },
        shares: {
            label: 'Shares',
            tooltipInfo: tooltipInfos.statistics.yearlySharesStatistic
        },
        likes: {
            label: 'Likes',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        comments: {
            label: 'Comments',
            tooltipInfo: tooltipInfos.statistics.yearlyCommentsStatistic
        },
        annotationClickThroughRate: {
            label: 'Annotation Click Through Rate',
            tooltipInfo: tooltipInfos.statistics.yearlyAnnotationClickThroughRateStatistic
        }
    },
    Instagram: {
        impressions: {
            label: 'Impressions',
            tooltipInfo: tooltipInfos.statistics.yearlyImpressionStatistic
        },
        reach: {
            label: 'Reach',
            tooltipInfo: tooltipInfos.statistics.yearlyReachStatistic
        },
        shares: {
            label: 'Shares',
            tooltipInfo: tooltipInfos.statistics.yearlySharesStatistic
        },
        likes: {
            label: 'Likes',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        comments: {
            label: 'Comments',
            tooltipInfo: tooltipInfos.statistics.yearlyCommentsStatistic
        },
        followersGained: {
            label: 'Followers Gained',
            tooltipInfo: tooltipInfos.statistics.yearlyFollowersGainedStatistic
        }
    },
    TikTok: {
        views: {
            label: 'Views',
            tooltipInfo: tooltipInfos.statistics.yearlyViewsStatistic
        },
        shares: {
            label: 'Shares',
            tooltipInfo: tooltipInfos.statistics.yearlySharesStatistic
        },
        likes: {
            label: 'Likes',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        comments: {
            label: 'Comments',
            tooltipInfo: tooltipInfos.statistics.yearlyCommentsStatistic
        },
        duration: {
            label: 'Duration',
            tooltipInfo: tooltipInfos.statistics.yearlyDurationStatistic
        }
    },
    Overview: {
        views: {
            label: 'Views',
            tooltipInfo: tooltipInfos.statistics.yearlyViewsStatistic
        },
        followers: {
            label: 'Followers',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        engRate: {
            label: 'Eng. Rate',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        likes: {
            label: 'Likes',
            tooltipInfo: tooltipInfos.statistics.yearlyLikesStatistic
        },
        shares: {
            label: 'Shares',
            tooltipInfo: tooltipInfos.statistics.yearlySharesStatistic
        },
        comments: {
            label: 'Comments',
            tooltipInfo: tooltipInfos.statistics.yearlyCommentsStatistic
        },
    }
};

export const demographicDisplayInfo: { [key: string]: { [key: string]: { label: string; tooltipInfo: string } } } = {
    Overview: {
        gender: {
            label: 'Gender',
            tooltipInfo: tooltipInfos.statistics.platformGenderComparison
        },
        age: {
            label: 'Age',
            tooltipInfo: tooltipInfos.statistics.platformAgeComparison
        },
        country: {
            label: 'Country',
            tooltipInfo: tooltipInfos.statistics.platformCountryComparison
        },
        city: {
            label: 'City',
            tooltipInfo: tooltipInfos.statistics.platformCityComparison
        },
    }
};


export const socialMediaTypes = {
    YouTube: "YouTube",
    Instagram: "Instagram",
    TikTok: "TikTok",
    // Twitch: "Twitch"
};

export const youtubePostTypes = {
    Video: "VIDEO",
    Short: "SHORT",
};

export const analyticTypes = {
    Metrics: "Metrics",
    Audience: "Audience"
};

export const subscriptionTypes = {
    values: {
        Month: "month",
        Year: "year"
    },
    labels: {
        Monthly: "Monthly",
        Yearly: "Yearly"
    }
};

export const demographicMetricTypesLabels = {
    Followers: "Followers",
    Reach: "Reach",
};

export const demographicMetricTypes = {
    Followers: "follower_demographics",
    ReachedAudience: "reached_audience_demographics",
};

export const pronouns = {
    He_Him: "He / Him",
    She_Her: "She / Her",
    They_Them: "They / Them",
};

export const skills = {
    Design: 'Design',
    GraphicDesign: 'Graphic Design',
    WebDesign: 'Web Design',
    Figma: 'Figma',
    AccountManagement: 'Account Management',
    ContentCreation: 'Content Creation',
};

export const companies = {
    CIBC: "CIBC",
    RBC: "RBC",
    TD: "TD",
    Google: "Google"
};

export const cities = {
    Toronto: "Toronto",
    Mississauga: "Mississauga",
    London: "London",
};

export const provinces = {
    Ontario: "Ontario",
    Alberta: "Alberta",
    England: "England",
};

export const locationTypes = {
    onSite: "On-Site",
    hybrid: "Hybrid",
    remote: "Remote",
};

export const employmentTypes = {
    selfEmployed: "Self-employed",
    freelance: "Freelance",
    internship: "Internship",
    apprenticeship: "Apprenticeship",
    contractFullTime: "Contract Full-time",
    permanentPartTime: "Permanent Part-time",
    contractPartTime: "Contract Part-time",
    casualOnCall: "Casual / On-call",
    seasonal: "Seasonal",
    permanentFullTime: "Permanent Full-time",
    coop: "Co-op"
};

export const industries = {
    retail: "Retail",
    banking: "Banking",
    foodAndServices: "Food and Services"
};

export const collabTypes = {
    YouTube: {
        short: "Short",
        video: "Video",
        videoMention: "Video Mention",
        captionMention: "Caption Mention",
    },
    Instagram: {
        feedPost: "Feed Post",
        reel: "Reel",
        story: "Story",
        captionMention: "Caption Mention",
    },
    TikTok: {
        photoPost: "Photo Post",
        video: "Video",
        captionMention: "Caption Mention",
    }
};

export const applicationTypes = {
    Requested: "Requested",
    Received: "Received"
};

export const contractTypes = {
    Requested: "Requested",
    Received: "Received"
};

export const applicationStatus = {
    requested: "Requested",
    pendingRequestorApproval: "Pending Requestor Approval",
    approved: "Approved",
    completed: "Completed",
    canceled: "Canceled"
 };

 export const requestorApplicationStatus = {
    requested: "Requested",
    canceled: "Canceled"
 };

 export const receiverApplicationStatus = {
    approved: "Approved",
    requested: "Requested",
 };

export const contractStatus = {
   inProgress: "In Progress",
   completed: "Completed",
   pendingRequestorApproval: "Pending Requestor Approval",
   canceled: "Canceled"
};

export const editContractStatus = {
    inProgress: "In Progress",
    completed: "Completed",
    canceled: "Canceled"
};



export const regexPatterns = {
    password: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/),
    website: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi,
    lettersOnlyWithSpace: /^[A-Za-z\s]+$/,
    //socialMediaURL: /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|embed)\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})$/i,
    socialMediaURL: /^(https?:\/\/)?(www\.)?(instagram\.com|youtube\.com|twitch\.com|tiktok\.com)\/.*$/i,
    facebookURL: /^(https?:\/\/)?(www\.)?(facebook\.com)\/.*$/i,
    linkedinURL: /^(https?:\/\/)?(www\.)?(linkedin\.com)\/.*$/i,
    twitterURL: /^(https?:\/\/)?(www\.)?(twitter\.com)\/.*$/i,
    youtubeURL: /^(https?:\/\/)?(www\.)?(youtube\.com)\/.*$/i,
    instagramURL: /^(https?:\/\/)?(www\.)?(instagram\.com)\/.*$/i,
    tiktokURL: /^(https?:\/\/)?(www\.)?(tiktok\.com)\/.*$/i,
    twitchURL: /^(https?:\/\/)?(www\.)?(twitch\.tv)\/.*$/i,
    amazonURL: /^(https?:\/\/)?(www\.)?(amazon\.com)\/.*$/i,
    postalCode: /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/,
    customURL: /^[a-zA-Z0-9._-]{3,100}$/,
};

export const storageValues = {
    jwtToken: "jwtToken",
    accessToken: "accessToken",
    bearerToken: "bearerToken"
};

export const fieldNames = {
    firstname: "firstname",
    lastname: "lastname",
    age: "age",
    jobTitle: "jobTitle",
    pronouns: "pronouns",
    languages: "languages",
    primaryPhoneNumber: "primaryPhoneNumber",
    email: "email",
    password: "password",
    newPassword: "newPassword",
    confirmPassword: "confirmPassword",
    rememberMe: "rememberMe",
    termOfUse: "termOfUse",
    country: "country",
    city: "city",
    province: "province",
    state: "state",
    primaryAddress: "primaryAddress",
    secondaryAddress: "secondaryAddress",
    zipCode: "zipCode",
    postalCode: "postalCode",
    website: "website",
    socialLinks: "socialLinks",
    customUrl: "customUrl",
    accountLink: "accountLink",
    accountUsername: "accountUsername",
    accountEmail: "accountEmail",
    pinnedPostLink1: "pinnedPostLink1",
    pinnedPostLink2: "pinnedPostLink2",
    pinnedPostLink3: "pinnedPostLink3",
    isPrimaryAccount: "isPrimaryAccount",
    isDisabledAccount: "isDisabledAccount",
    accountPostFilter: "accountPostFilter",
    accountMetricSelections: "accountMetricSelections",
    accountMetricLineSelections: "accountMetricLineSelections",
    filterPostsBy: "filterPostsBy",
    title: "title",
    employmentType: "employmentType",
    companyName: "companyName",
    locationtype: "locationType",
    endDate: "endDate",
    industry: "industry",
    description: "description",
    skills: "skills",
    mediaContents: "mediaContents",
    isCurrentRole: "isCurrentRole",
    dates: "dates",
    mediaContentLink1: "mediaContentLink1",
    mediaContentLink2: "mediaContentLink2",
    youtube: "youtube",
    instagram: "instagram",
    tiktok: "tiktok",
    facebook: "facebook",
    twitch: "twitch",
    amazon: "amazon",
    about: "about",
    checkoutBtn: "checkoutBtn",
    collabBtn: "collabBtn",
    price: "price",
    socialMediaType: "socialMediaType",
    collabType: "collabType",
    thumbnailImg: "thumbnailImg",
    deliveryTime: "deliveryTime",
    additionalInfo: "additionalInfo",
    status: "status",
    didRequestorApprove: "didRequestorApprove"
};

export const placeholders = {
    multiSelect: "Please select values",
    singleSelect: "Please select a value",
    defaultSelect: "Please select",
    defaultText: "Please enter text",
    //COLLAB MODAL
    collabTitle: "Ex: ",
    collabDescription: "Ex: A mention and presentation of your account",
    //APPLICATION MODAL
    additionalInfo: "Ex: Please make the video under 2 minutes and include clear product shots",
    //EXPERIENCE MODAL
    jobTitle: "Ex: Content Creator",
    companyName: "Ex: Instagram",
    city: "Ex: Toronto",
    province: "Ex: Ontario",
    country: "Ex: Canada",
    month: "Month",
    year: "Year",
    date: "Date",
    startDate: "Start Date",
    endDate: "End Date",
    industry: "Ex: Retail",
    jobDescription: "Ex: As a content creator, I crafted compelling content for various digital platforms, leveraging SEO techniques to drive a 50% increase in blog traffic",//"Describe your role and responsibilities, including details about the type of content you created, platforms used, audience targeted, and any specific achievements or projects you were involved in."
    //LOGIN / SIGN UP
    email: "Email",
    emailExample: "Ex: example@gmail.com",
    password: "Password",
    confirmPassword: "Confirm Password",
    //SOCIAL MEDIA ACCOUNT
    youtubePost: "Ex: https://www.youtube.com/watch?v=6kiO9NtTdCM",
    username: "Ex: example123",
    postPlaceholder: postPlaceholder,
    //PROFILE INFO
    about: "Ex: Passionate Content Creator who has 5 years of experience in the field. Experienced in content creation in different social media platforms such as YouTube, Instagram and TikTok",
    postalCode: "Ex: A5A 5A5",
    website: "Ex: https://www.example.com",
    youtubeLink: "Ex: https://www.youtube.com/@username",
    instagramLink: "Ex: https://www.instagram.com/username",
    tiktokLink: "Ex: https://www.tiktok.com/@username",
    faceboookLink: "Ex: https://www.facebook.com/username",
    twitchLink: "Ex: https://www.twitch.com/username",
    amazonLink: "Ex: https://www.amazon.com/username",
    customUrl: "Ex: john-doe",
    languages: "Ex: English"
};


export const subTextPosition = {
    horizontal: "horizontal",
    vertical: "vertical"
}

export const companyEmail = "business.lyncsy@gmail.com";
export const companyContactEmail = "contact.lyncsy@gmail.com";
export const companyInstagramLink = "https://www.instagram.com/lyncsy_official/";

export const profileProgress: { [key: string]: string } = {
    "generalInformationCompleted": "Profile Information",
    "socialMediaAccountCompleted": "Social Media Account",
    "connectedAccountCompleted": "Connected Social Media Account",
    "experienceCompleted": "Experience",
};

export const subscriptionHighlights = {
    free: [
        'Instantly connect all social media content',
        'Custom shareable URL',
        'Latest 20 YouTube posts',
        'Latest 20 Instagram posts',
        'Latest 20 TikTok posts',
    ],
    smAccount: [
        'Extensive post analytics for 1 platform',
        'In-depth audience insights for 1 platform',
        'Customizable analytics metrics',
        'Flexible post display options',
        'Access to highlights and analytics page',
    ],
    allAccounts: [
        'Complete post analytics for all platforms',
        'In-depth audience insights for all platforms',
        'Customizable analytics metrics',
        'Flexible post display options',
        'Access to overview and analytics page',
    ]
};

interface CollabDescription {
    [key: string]: {
        [key: string]: string;
    };
}

export const collabDescriptions: CollabDescription = {
    YouTube: {
        "Short": "I will create a YouTube Short with you that is under 60 seconds",
        "Video": "I will create a YouTube Video with you that is over 60 seconds",
        "Video Mention": "I will mention your account or brand in a YouTube Video or Short",
        "Caption Mention": "I will mention your account or brand in a YouTube Post Caption",
    },
    Instagram: {
        "Feed Post": "I will create an Instagram Feed Post with you that includes one or more photos",
        "Reel": "I will create an Instagram Reel with you that is under 90 seconds",
        "Story": "I will mention your account or brand in an Instagram Story",
        "Caption Mention": "I will mention your account or brand in an Instagram post caption",
    },
    TikTok: {
        "Photo Post": "I will create a TikTok Photo Post with you that includes one or more photos",
        "Video": "I will create a TikTok Video with you that is under 90 seconds",
        "Caption Mention": "I will mention your account or brand in a TikTok Post Caption",
    }
};
