import dayjs, { Dayjs, months } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/en';

dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.locale('en');
export type AppDate = Dayjs;

export class Dates {

  static setLocale(locale: string): void {
    dayjs.locale(locale);
  }

  static getToday(): AppDate {
    return dayjs();
  }

  static getClearDate(): AppDate {
    return this.getToday().hour(0).minute(0).second(0).millisecond(0);
  }

  static getMonths(): string[] {
    return dayjs.months();
  }

  static getDays(): string[] {
    return dayjs.weekdaysShort();
  }

  static getMonthYearFromDate(date: string | Date | dayjs.Dayjs, monthFormat: string = "MMM", yearFormat: string = "YYYY"): string {
    const dayjsDate = dayjs(date);
    const month = dayjsDate.format(monthFormat); 
    const year = dayjsDate.format(yearFormat); 
    return `${month} ${year}`;
  }

  static getYearMonthDifference = (startDate: string | Date | dayjs.Dayjs, endDate: string | Date): string => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
  
    const diff = end.diff(start, 'month');
  
    const years = Math.floor(diff / 12);
    const months = diff % 12;
  
    if (years === 0) {
      return months <= 1 ? "1 month" : `${months} mos`;
    } else {
      if (months <= 0) {
        return years === 1 ? "1 year" : `${years} yrs`; 
      } else {
        return years === 1 ? `${years} yr ${months} mos` : `${years} yrs ${months} mos`;
      }
    }
  }
  

  static getDate(date: number | string | Date): AppDate {
    return dayjs(date);
  }

  static isDayjs(date: Date): boolean{
    return dayjs.isDayjs(date);
  }

  static getDateWithFormat(date: number | string | Date, query: string): AppDate {
    return this.getDate(dayjs(date).format(query));
  }

  static format(date: AppDate | string | number , query: string): string {
    if (typeof date === 'string' || typeof date === 'number') {
      return dayjs(date).format(query);
    } else {
      console.log(date, typeof date);
      return date.format(query);
    }
  }

  static getDaysUntilExpiration(endDate: Date | Dayjs): number {
    const currentDate = dayjs().startOf('day');
    const end = dayjs(endDate).startOf('day');
    
    // Calculate the difference in days
    const daysUntilExpiration = end.diff(currentDate, 'day');
    
    return daysUntilExpiration;
  }
}
