import connectAcctImg from "@app/assets/images/connect-account-image.svg";
import collabsImg from "@app/assets/images/collabs-image.svg";
//import analyticsImg from "@app/assets/images/analytics-image.svg";
import brandDealsImg from "@app/assets/images/brand-deals-image.svg";

interface Onboarding {
  id: number;
  title: string;
  img: string;
  description: string;
}

export const onboardingSteps : Onboarding[] = [
    {
      id: 1,
      img: connectAcctImg,
      title: ' 1. Connect Account ',
      description: ' Sign up easily with your social media login and connect your accounts ',
    },
    {
      id: 2,
      img: collabsImg,
      title: ' 2. Set Up Collaborations ',
      description: ' Create collab options in minutes and start requesting and receiving deals ',
    },
    {
      id: 3,
      img: brandDealsImg,
      title: ' 3. Connect & Collaborate ',
      description: 'Connect with brands and creators. Start earning and grow your reach!',
    },
    // {
    //   id: 2,
    //   img: analyticsImg,
    //   title: ' 2. Customize Analytics ',
    //   description: ' Customize analytics to showcase what matters to you and your followers ',
    // },
    // {
    //   id: 3,
    //   img: brandDealsImg,
    //   title: ' 3. Attract Brand Deals ',
    //   description: 'Boost your brand appeal by adding your custom link into your social media profiles!',
    // },
  ];