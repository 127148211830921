import React from 'react';
import * as S from './Highlight.styles'
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { highlights } from '@app/constants/config/highlights';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage'; 
import { useResponsive } from '@app/hooks/useResponsive';

export const Highlight: React.FC = () => {
    const { mobileOnly } = useResponsive();

    const highlightCols = highlights.map((card, i) => (
        <S.HighlightCol key={i.toString()} $index={i} md={24} lg={7}>
                <div>
                    <BaseImage key={`img${i}`} src={card.img} alt="" width={150} height={150} preview={false} />
                </div>
                <S.TitleWrapper key={`title${i}`}>
                    <S.Title style={{ whiteSpace: 'pre-line' }}>{card.title}</S.Title>
                </S.TitleWrapper>
                <S.TextWrapper key={`description${i}`}>
                    <S.Text>{card.description}</S.Text>
                </S.TextWrapper>
        </S.HighlightCol>
    ));

    return (
        <ScrollOverPack style={{
            width: '100%',
            margin: 'auto',
            overflow: 'hidden',
            minHeight: '20rem',
            padding: "0",
        }}>
            <QueueAnim
                component='Row'
                type="bottom"
                leaveReverse
                duration={450}
            >
                <S.HiglightRow key="row" justify="space-between">
                    {highlightCols}
                </S.HiglightRow>
            </QueueAnim>
        </ScrollOverPack>
    );
};
