import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './FAQ.styles';
import { faq } from '@app/locales/constants';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';

interface FAQProps {
    isFromHome?: boolean;
}

export const FAQ: React.FC<FAQProps> = ({ isFromHome=false }) => {
    const { mobileOnly } = useResponsive();

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: faq.questions.isItSecure,
            children: <S.Text>{faq.answers.isItSecure}</S.Text>,
        },
        {
            key: '2',
            label: faq.questions.whyUseIt,
            children: <S.Text>{faq.answers.whyUseIt}</S.Text>,
        },
        {
            key: '3',
            label: faq.questions.whoUseIt,
            children: <S.Text>{faq.answers.whoUseIt}</S.Text>,
        },
        {
            key: '4',
            label: faq.questions.howCollabWorks,
            children: <S.Text>{faq.answers.howCollabWorks}</S.Text>,
        },
        {
            key: '5',
            label: faq.questions.howToEnsureCollab,
            children: <S.Text>{faq.answers.howToEnsureCollab}<S.StyledLink href={`mailto:${faq.contactEmail}`}> {faq.contactEmail}</S.StyledLink>.</S.Text>,
        },
        {
            key: '6',
            label: faq.questions.isItFree,
            children: <S.Text>{faq.answers.isItFree}</S.Text>,
        },
        {
            key: '7',
            label: faq.questions.whyRequestAccountInfo,
            children: <S.Text>{faq.answers.whyRequestAccountInfo}</S.Text>,
        },
    ];

    return (
        <>
        <BaseRow>
        <S.TitleCol xs={24} $isFromHome={isFromHome}>
                <S.Title>
                    <S.Span>Frequently{isFromHome && mobileOnly && <br/>} Asked Questions</S.Span>
                </S.Title>
            </S.TitleCol>
            <BaseCol xs={24}>
            <S.StyledCollapse
                expandIconPosition="end"
                items={items}
                ghost
                $isFromHome={isFromHome}
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
            </BaseCol>
        </BaseRow>
        </>
    );
};
