import { ProfileInfoFormValues } from "@app/components/profile/profileCard/profileFormNav/nav/ProfileInfo/ProfileInfo";
import { Collab, Experience, InfluencerModel, PersonalInfo, SocialMediaAccount } from "@app/domain/InfluencerModel";
import { AddressTransformer } from "./AddressTransformers";
import { SocialMediaAccountInfoFormValues } from "@app/components/profile/profileCard/profileFormNav/nav/SocialMediaAccounts/SocialMediaAccounts";
import { ExperienceInfoFormValues } from "@app/components/profile/profileCard/profileFormNav/nav/Experience/modals/ExperienceModal";
import { Dates } from "@app/constants/Dates";
import { CollabInfoFormValues } from "@app/components/profile/profileCard/profileFormNav/nav/Collabs/modal/CollabModal";
import { getCollabDescription } from "@app/utils/utils";

export class InfluencerTransformer {
  static databaseModelToInfluencerModel(influencer: any): InfluencerModel {
    return {
      id: influencer._id,
      firstname: influencer.firstname,
      lastname: influencer.lastname,
      userName: '',
      email: influencer.email,
      primaryPhoneNumber: influencer.primaryPhoneNumber,
      profileImg: influencer.profileImg,
      pronouns: influencer.pronouns,
      jobTitle: influencer.jobTitle,
      age: influencer.age,
      languages: influencer.languages,
      address: influencer.address,
      about: influencer.about,
      //TODO: these might be needed later when we make influencers dashboard
     // demographicReach: influencer.demographicReach,
      //influencerCategories: influencer.influencerCategories,
      //totalFollowers: influencer.totalFollowers,
      website: influencer.website,
      socialsLinks: influencer.socialsLinks,
      productLinks: influencer.productLinks,
      customUrl: influencer.customUrl,
      hadFreeSubscriptionTrial: influencer.hadFreeSubscriptionTrial,
      profileProgress: influencer.profileProgress,
      subscriptions: influencer.subscriptions,
      socialMediaAccounts: influencer.socialMediaAccounts ? InfluencerTransformer.databaseModelToSocialMediaAccounts(influencer.socialMediaAccounts) : [],
      experiences: influencer.experiences ? InfluencerTransformer.databaseModelToExperience(influencer.experiences) : [],
      collabs: influencer.collabs ? InfluencerTransformer.databaseModelToCollab(influencer.collabs) : [],
      createdAt: influencer.createdAt,
      updatedAt: influencer.updatedAt,
    }
  }

  static databaseModelToSocialMediaAccounts(socialMediaAccounts: any): SocialMediaAccount[] {
    return socialMediaAccounts.map((values: any) => ({
      databaseId: values._id,
      hasAccountId: values.accountId !== null || values.accountId !== undefined, 
      socialMediaType: values.socialMediaType,
      isPrimaryAccount: values.isPrimaryAccount,
      isDisabledAccount: values.isDisabledAccount,
      accountPostFilter: values.accountPostFilter,
      accountMetricSelections: values.accountMetricSelections,
      accountMetricLineSelections: values.accountMetricLineSelections,
      accountUsername: values.accountUsername,
      accountCategories: values.accountCategories,
     // accountDemographics: values.accountDemographics,
      accountCustomUrl: values.accountCustomUrl,
      accountEmail: {
        content: values.accountEmail?.content,
        verified: values.accountEmail?.verified,
      },
    }));
  }


  static databaseModelToExperience(experiences: any): Experience[] {
    return experiences.map((values: any) => ({
      databaseId: values._id,
      jobTitle: values.jobTitle,
      employmentType: values.employmentType,
      companyName: values.companyName,
      companyLogo: values.companyLogo,
      //location: string;
      city: values.city,
      province: values.province,
      country: values.country,
      locationType: values.locationType,
      isCurrentRole: values.isCurrentRole,
      startDate: Dates.getDate(values.startDate),
      endDate: values.endDate ? Dates.getDate(values.endDate) : values.endDate,
      industry: values.industry,
      description: values.description,
      skills: values.skills,
    }));
  };

  static databaseModelToCollab(collab: any): Collab[] {
    return collab.map((values: any) => ({
      databaseId: values._id,
      title: values.socialMediaType + " " + values.collabType,
      description: values.description || values.description !== '' ? values.description : getCollabDescription(values.socialMediaType, values.collabType),
      thumbnailImg: values.thumbnailImg,
      price: values.price,
      deliveryTime: values.deliveryTime,
      socialMediaType: values.socialMediaType,
      collabType: values.collabType
    }));
  };

  static profileInfoFormValuesToPersonalInfo(values: ProfileInfoFormValues): PersonalInfo {
    return {
      firstname: values.firstname,
      lastname: values.lastname,
      email: {
        content: values.email,
        verified: values.emailVerified,
      },
      primaryPhoneNumber: values.primaryPhoneNumber,
      pronouns: values.pronouns,
      jobTitle: values.jobTitle,
      age: values.age,
      languages: values.languages,
      address: AddressTransformer.transform(values),
      about: values.about,
      website: values.website,
      socialsLinks: {
        youtube: values.youtube,
        instagram: values.instagram,
        tiktok: values.tiktok,
        facebook: values.facebook,
        twitch: values.twitch,
        amazon: values.amazon,
      },
      productLinks: values.productLinks,
      customUrl: values.customUrl,
    }
  }


  static socialMediaAccountInfoFormValuesToSocialMediaAccount(values: SocialMediaAccountInfoFormValues): SocialMediaAccount {
    return {
      databaseId: values.databaseId,
      hasAccountId: values.hasAccountId,
      socialMediaType: values.socialMediaType,
      isPrimaryAccount: values.isPrimaryAccount,
      isDisabledAccount: values.isDisabledAccount,
      accountPostFilter: values.accountPostFilter ?? '',
      accountMetricSelections: values.accountMetricSelections ?? [],
      accountMetricLineSelections: values.accountMetricLineSelections ?? [],
      accountUsername: values.accountUsername,
      //accountLink: values.account,
      accountEmail: {
        content: values.accountEmail,
        verified: values.accountEmailVerified,
      },
      //totalFollowers?: values.to,
      //accountCategories?: values.,
      //accountDemographics?: values.,
    }
  };

  static experienceInfoFormValuesToExperience(values: ExperienceInfoFormValues): Experience {
    return {
      databaseId: values.databaseId,
      jobTitle: values.jobTitle,
      employmentType: values.employmentType,
      companyName: values.companyName,
      companyLogo: values.companyLogo,
      //location: string;
      city: values.city,
      province: values.province,
      country: values.country,
      locationType: values.locationType,
      isCurrentRole: values.isCurrentRole,
      startDate: values.startDate ?? Dates.getToday().toDate(),
      endDate: values.endDate ?? undefined,
      industry: values.industry,
      description: values.description,
      skills: values.skills,
    //  mediaContents: [values.mediaContentLink1 ?? '', values.mediaContentLink2 ?? ''],
    }
  };

  static collabInfoFormValuesToCollab(values: CollabInfoFormValues): Collab {
    return {
      databaseId: values.databaseId,
      thumbnailImg: values.thumbnailImg,
      deliveryTime: values.deliveryTime,
      //TODO: when description becomes empty after social media type change
      //description: values.description || values.description !== '' ? values.description : getCollabDescription(values.socialMediaType, values.collabType),
      description: values.description ?? '',
      price: values.price,
      socialMediaType: values.socialMediaType,
      collabType: values.collabType,
    }
  };
}