import styled from 'styled-components';
import { BASE_COLORS, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';


export const Title = styled(BaseTypography.Text)`
  font-size: 1.6rem;
  font-weight: 800;
  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: 1.75rem;
  }

  @media only screen and ${media.xl} {
    font-size: 2.75rem;
  }
`;

export const Span = styled.span`
  font-weight: 800;
  text-align: left;
  color: var(--primary-color);
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.7rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.9rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.1rem;
  }
`;

export const Text = styled(Title)`
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-light-color);

  @media only screen and ${media.md} {
    font-size: 1.25rem;
  }

  @media only screen and ${media.xl} {
    font-size: 1.5rem;
  }
`;

export const TextWrapper = styled.div` 
  margin-bottom: 4rem;

  @media only screen and ${media.md} {
    margin-bottom: 4.5rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 5rem;
  }
`;


