import styled from 'styled-components';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal'; 
import { BASE_COLORS, BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { Form } from 'antd';

interface ButtonProps {
    $backgroundColor: string;
    $textColor: string;
  }

export const StyledModal = styled(BaseModal)`
  .ant-modal-body {
    max-height: calc(100vh - 50px); 
  }
`;

export const Title = styled(BaseTypography.Text)`
    font-size: ${FONT_SIZE.xs};
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.bold};

    @media only screen and ${media.xl} {
        font-size: ${FONT_SIZE.lg};
    }

    @media only screen and ${media.xxl} {
      font-size: ${FONT_SIZE.xxl};
    }
`;


export const Subtitle = styled(Title)`
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.bold};

      @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
        line-height: 1;
        font-size: ${FONT_SIZE.xxs}; 
     }
`;

export const Text = styled(Title)`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--text-main-color);
  line-height: 2;

  a {
    text-decoration: underline;
  }

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    line-height: 1;
    font-size: ${FONT_SIZE.xxs}; 
 }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  margin-top: 1rem;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    margin-top: 0.5rem;
    .ant-form-item-explain-error {
        font-size: ${FONT_SIZE.xxs};
    }
  }
`;

export const FormCheckbox = styled(BaseCheckbox)`
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  } 
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`;


export const LimitationText = styled(Text)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--text-light-color);
  line-height: 1;
  
  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    line-height: 1;
    font-size: ${FONT_SIZE.xxxs}; 
 }
`;

export const Description = styled(Text)`
   line-height: 2;
   font-weight: ${FONT_WEIGHT.semibold};
   @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    line-height: 1;
 }
`;

export const Span = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--text-main-color);
  line-height: 2;

  margin-right: 0.2rem;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    font-size: ${FONT_SIZE.xxs}; 
    line-height: 1;
 }
`;

export const StyledLink = styled.a`
  text-decoration: underline;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    font-size: ${FONT_SIZE.xxs}; 
    line-height: 1;
 }
`;

export const StyledButton = styled(BaseButton)<ButtonProps>`
    background-color: ${props => props.$backgroundColor};
    color: ${props => props.$textColor};
    margin-top: 1rem;

    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
        margin-top: 0.5rem;
    }
`;

export const IconAvatar = styled(BaseAvatar)`
  background-color: ${BASE_COLORS.white};
`;
