import React from 'react';
import { BaseAvatar } from '../BaseAvatar/BaseAvatar';
import * as S from './BaseTestimonial.styles';
import { BaseRate } from '../BaseRate/BaseRate';

export interface BaseTestimonialProps {
  author?: React.ReactNode;
 // imgUrl: string;
  title?: string;
  handleName: string;
  //date: number;
  rating: number;
  description: string;
  avatar?: string;
  //tags?: IHashTag[];
  className?: string;
}

export const BaseTestimonial: React.FC<BaseTestimonialProps> = ({
  //imgUrl,
  title,
  handleName,
  //date,
  rating,
  description,
  author,
  avatar,
  //tags,
  className,
}) => {
  return (
    <S.Wrapper className={className}>
      <S.Header>
        {!!avatar && <BaseAvatar src={avatar} alt="author" size={43} />}
        <S.AuthorWrapper>
          {author && <S.Author>{author}</S.Author>}
          <S.HandleName>{handleName}</S.HandleName>
        </S.AuthorWrapper>
      </S.Header>
      <S.InfoWrapper>
        <S.InfoHeader>
        <BaseRate allowHalf disabled defaultValue={rating} />
        </S.InfoHeader>
        <S.Description>{description}</S.Description>
      </S.InfoWrapper>
    </S.Wrapper>
  );
};
