import React from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { labels } from '@app/locales/constants';
import * as S from './Home.styles'
import { Feature } from './feature/Feature';
import { Header } from '@app/components/home/header/Header'
import { Testimonials } from './testimonials/Testimonials';
import { Footer } from './footer/Footer';
import { Banner } from './banner/Banner';
import { Onboarding } from './onboarding/Onboarding';
import AppCollabImg from '@app/assets/images/app-collab-demo.webp';
import AppCollabMgntImg from '@app/assets/images/app-collab-mgnt-demo.webp';
import AppMetricsImg from '@app/assets/images/app-metrics-demo.webp';
import { useResponsive } from '@app/hooks/useResponsive';
import { FAQ } from '../faq/FAQ';

export const Home: React.FC = () => {
    const { mobileAndTabletOnly, isDesktopSmall, isDesktopMedium } = useResponsive();
    return (
            <div style={{ background: "var(--background-color)" }}>
                <Header />
                <S.ContentRow gutter={[0, 80]}>
                    <BaseCol xs={0} sm={2} xxl={4}/>
                    <BaseCol xs={24} sm={20} xxl={16}>
                        <S.BannerCol xs={24}>
                            <Banner />
                        </S.BannerCol>

                        <S.FeatureCol xs={24}>
                            <Feature
                                title={labels.collabMarketing}
                                subTitle={labels.collabSetUp}
                                isImgFirst={false}
                                img={AppCollabImg}
                                btnText={labels.seeCollabsDemo}
                                index="1"
                            />
                        </S.FeatureCol>

                        <S.FeatureCol xs={24}>
                            <Feature
                                title={mobileAndTabletOnly ? labels.collabManagementMobile : labels.collabManagementDesktop}
                                subTitle={labels.collabControl}
                                isImgFirst={!(mobileAndTabletOnly || isDesktopSmall && !isDesktopMedium)}
                                img={AppCollabMgntImg}
                                btnText={labels.seeCollaborationDemo}
                                index="2"
                            />
                        </S.FeatureCol>

                        <S.FeatureCol xs={24}>
                            <Feature
                                title={labels.comprehensiveAnalytics}
                                subTitle={labels.analyticsBenefit}
                                isImgFirst={false}
                                img={AppMetricsImg}
                                btnText={labels.seeAnalyticsDemo}
                                index="3"
                            />
                        </S.FeatureCol>

                        {/* <S.FeatureCol xs={24}>
                            <Feature
                                title={labels.contentResume}
                                subTitle={labels.contentManagement}
                                isImgFirst={false}
                                img={AppPostImg}
                                btnText={labels.seeContentsDemo}
                                index="1"
                            />
                        </S.FeatureCol>

                        <S.FeatureCol xs={24}>
                            <Feature
                                title={labels.comprehensiveAnalytics}
                                subTitle={labels.analyticsBenefit}
                                isImgFirst={!(mobileAndTabletOnly || isDesktopSmall && !isDesktopMedium)}
                                img={AppMetricsImg}
                                btnText={labels.seeAnalyticsDemo}
                                index="2"
                            />
                        </S.FeatureCol>

                        <S.FeatureCol xs={24}>
                            <Feature
                                title={labels.influencerSearch}
                                subTitle={labels.influencerCollaboration}
                                isImgFirst={false}
                                img={AppInfluencerSearchImg}
                                btnText={labels.seeCollaborationDemo}
                                index="3"
                            />
                        </S.FeatureCol> */}

                        <BaseCol xs={24}>
                            <Onboarding />
                        </BaseCol>

                        <S.FAQCol xs={24}>
                            <FAQ isFromHome/>
                        </S.FAQCol>

                        <BaseCol xs={24}>
                            <Testimonials />
                        </BaseCol>
                    </BaseCol>
                    <BaseCol xs={0} sm={2} xxl={4}/>
                </S.ContentRow >
                <Footer isFromHome />
            </div>
    );
};
