import { shadeColor } from '@app/utils/utils';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';

const chartColors = {
  chartTooltipLabel: '#6a7985',
  chartLegendLabel: '#6a7985',
  chartColorBlueFull: 'rgba(51, 156, 253, 1)',
  chartColorBlueStrong: 'rgba(51, 156, 253, 0.7)',
  chartColorBlueMedium: 'rgba(51, 156, 253, 0.4)',
  chartColorBlueLight: 'rgba(51, 156, 253, 0.2)',
  chartColorAquaFull: 'rgba(51, 245, 225, 1)',
  chartColorAquaStrong: 'rgba(51, 245, 225, 0.7)',
  chartColorAquaMedium: 'rgba(51, 245, 225, 0.4)',
  chartColorAquaLight: 'rgba(51, 245, 225, 0.2)',
  chartColorCyanFull: 'rgba(51, 215, 255, 1)',
  chartColorCyanStrong: 'rgba(51, 215, 255, 0.7)',
  chartColorCyanMedium: 'rgba(51, 215, 255, 0.4)',
  chartColorCyanLight: 'rgba(51, 215, 255, 0.2)',
  chartColorGreenFull: 'rgba(51, 235, 175, 1)',
  chartColorGreenStrong: 'rgba(51, 235, 175, 0.7)',
  chartColorGreenMedium: 'rgba(51, 235, 175, 0.4)',
  chartColorGreenLight: 'rgba(51, 235, 175, 0.2)',
  chartColorLavenderFull: 'rgba(138, 170, 255, 1)',
  chartColorLavenderStrong: 'rgba(138, 170, 255, 0.7)',
  chartColorLavenderMedium: 'rgba(138, 170, 255, 0.4)',
  chartColorLavenderLight: 'rgba(138, 170, 255, 0.2)',
  chartColorPurpleFull: 'rgba(170, 138, 255, 1)',
  chartColorPurpleStrong: 'rgba(170, 138, 255, 0.7)',
  chartColorPurpleMedium: 'rgba(170, 138, 255, 0.4)',
  chartColorPurpleLight: 'rgba(170, 138, 255, 0.2)',
  chartColorGray: 'rgba(211, 211, 211, 0.7)',
};

export const darkColorsTheme: ITheme = {
  primary: '#339CFD',
  primary1: '#7568f6',
  primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)',
  light: '#696969',
  secondary: '#0072DD',
  error: '#FF5252',
  warning: '#FFB765',
  success: '#57D682',
  background: '#25284B',
  bodyBackground: '#fafafa',
  secondaryBackground: '#1c2137',
  secondaryBackgroundSelected: shadeColor('#1c2137', -5),
  additionalBackground: '#1D203E',
  collapseBackground: '#1D203E',
  timelineBackground: '#f5f5f5',
  siderBackground: '#121430',
  spinnerBase: '#339CFD',
  scroll: '#797C9A',
  border: '#ffffff',
  borderNft: '#797C9A',
  textMain: '#ffffff',
  textLight: '#9A9B9F',
  textSuperLight: '#444',
  textSecondary: '#ffffff',
  textDark: '#404040',
  textNftLight: '#797C9A',
  textSiderPrimary: '#339CFD',
  textSiderSecondary: '#797C9A',
  subText: '#a9a9a9',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: 'none',
  boxShadowHover: 'none',
  boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  boxShadowNftSecondary:
    '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  dashboardMapBackground: '#25284b',
  dashboardMapCircleColor: '#ACAEC1',
  dashboardMapControlDisabledBackground: '#7e7e7e',
  notificationSuccess: '#EFFFF4',
  notificationPrimary: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  tagBackground: '#e0e5eb',
  heading: BASE_COLORS.white,
  borderBase: '#a9a9a9',
  disable: '#7e7e7e',
  disabledBg: '#1c2137',
  layoutBodyBg: '#1e2142',
  layoutHeaderBg: '#1e2142',
  layoutSiderBg: '#121430',
  inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
  itemHoverBg: '#1c2137',
  backgroundColorBase: '#1c2137',
  avatarBg: '#1c2137',
  alertTextColor: '#000',
  breadcrumb: '#a9a9a9',
  icon: '#a9a9a9',
  iconHover: '#ffffff',
  ...chartColors,
};

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
};
