import influencerReducer from './influencerSlice';
//import paymentReducer from './paymentSlice';
import authReducer from '@app/store/slices/authSlice';
import contractReducer from './contractSlice';
import applicationReducer from './applicationSlice';
//import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
//mport pwaReducer from '@app/store/slices/pwaSlice';

export default {
  //user: userReducer,
  influencer: influencerReducer,
  //payment: paymentReducer,
  applications: applicationReducer,
  contracts: contractReducer,
  auth: authReducer,
  //nightMode: nightModeReducer,
  theme: themeReducer,
  //pwa: pwaReducer,
};
