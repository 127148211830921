import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
//import deDe from 'antd/lib/locale/de_DE';
//import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
//import { useLanguage } from './hooks/useLanguage';
//import { usePWA } from './hooks/usePWA';
//import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { Analytics } from '@vercel/analytics/react';

const App: React.FC = () => {
  //const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  //usePWA();

  //useThemeWatcher();

  return (
    <>
      <meta
        name="theme-color"
        content='#FFFFFF'
      //TODO: use themeObject[theme].background when dark mode is complete right now set to white
      />
      <GlobalStyle />
      <HelmetProvider>
        {/* //TODO: check if you need this */}
        {/* <meta httpEquiv="Content-Security-Policy" content="default-src *; style-src 'self' 'unsafe-inline'; script-src 'self' 'unsafe-inline';" /> */}
        <ConfigProvider>
          {/* <ConfigProvider locale={language === 'en' ? enUS : deDe}> */}
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
      <Analytics />
    </>
  );
};

export default App;
