import styled, { css } from 'styled-components';
import { BASE_COLORS, BORDER_RADIUS, BREAKPOINTS, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';


interface HighlightColProps {
  $index: number;
}

export const HighlightCol = styled(BaseCol)<HighlightColProps>`
    padding: 1.2rem;
    border-radius: ${BORDER_RADIUS};

    background: var(--secondary-color);

    margin-bottom: 1rem;

    @media only screen and ${media.xs} {
      padding: 1rem 1rem 2rem 1rem !important;
    }

    @media only screen and ${media.sm} {
      padding: 1rem 1rem 1.2rem 1rem !important;
    }

    @media only screen and ${media.lg} {
      padding: 1.2rem 1.2rem 1.8rem 1.2rem !important;
      border: none; 
    }

    @media only screen and ${media.xxxl} {
      padding: 1.2rem 4rem !important;
    }

    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
      ${({ $index }) =>
      $index === 2 &&
      css`
          margin-bottom: 0;
      `}
    }

`;

export const HiglightRow = styled(BaseRow)`
text-align: center !important;
padding: 2.5rem 0 2.5rem 0;  

@media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
  padding: 0 0 2.5rem 0;  
}
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and ${media.md} {
    margin-bottom: 0.7rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1rem;
  }
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 1.3rem;
  font-weight: 800;

  color: ${BASE_COLORS.white};
  @media only screen and ${media.md} {
    font-size: 1.25rem;
  }

  @media only screen and ${media.xl} {
    font-size: 1.4rem;
  }
`;

export const TextWrapper = styled.div`
    margin-top: 0.5rem;

    @media only screen and ${media.md} {
    margin-top: 0.7rem;
    }

    @media only screen and ${media.xl} {
    margin-top: 1rem;
    }
`;

export const Text = styled(Title)`
  font-size: 0.9rem;
  font-weight: 300;
  color: ${BASE_COLORS.white};

  @media only screen and ${media.xl} {
    font-size: 0.88rem;
  }
`;




  


