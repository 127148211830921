import { NotificationType } from '@app/components/common/BaseNotification/BaseNotification';
import { Priority } from '@app/constants/enums/priorities';
import { BaseBadgeProps } from '@app/components/common/BaseBadge/BaseBadge';
import { IHashTag } from '@app/components/common/BaseHashTag/BaseHashTag';
import { collabDescriptions, colors, regexPatterns, socialMediaTypes } from '@app/locales/constants';
import { Severity } from '@app/interfaces/interfaces';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

// type MarkArea = {
//   xAxis: number;
// };

// export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
//   data.map((el, index) => [
//     {
//       xAxis: 2 * index,
//     },
//     {
//       xAxis: 2 * index + 1,
//     },
//   ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberCountWithUnit = (count?: number, precision?: number): string  => {
  if (!count) return '';
  
  precision = precision ? precision : 0;
  if (count >= 1000000) {
    return ((count - 1000000) < 99999) ? `${(count / 1000000).toFixed(0)}M` : `${(count / 1000000).toFixed(1)}M`;
  } else if (count >= 1000) {
    return `${(count / 1000).toFixed(0)}K`;
  } else {
    //For rates and percentage (e.g eng rate)
    return count.toFixed(precision).toString();
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const msToH = (ms: number): number => Math.floor(ms / 3600000);

export const hToMS = (h: number): number => h * 3600000;


export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info';
  }

  return status;
};


export const getDefaultTags = (tagTitles: string[], tagId: string): IHashTag[] => {
  const color = colors.textLight;
  const bgColor = colors.tagBackground;
  return tagTitles.map((title, index) => {
    const id = `${tagId}_${index}`;
    return {
      id,
      title,
      color,
      bgColor,
    };
  });
};

export const getOptions = (options: Record<string, string>): Array<{ value: string; label: string; }> => {
  return Object.values(options).map((value: string) => (
    {
      value: value,
      label: value,
    }
  ));
};

export const getArrayFilterOptions = (
  options: string[],
): Array<{ value: string; label: string; }> => {
    return options.map((value: string) => ({
      value: value,
      label: value,
    }));
};

export const getRegexForSocialMedia = (selectedSocialMedia: string) : RegExp => {
  switch (selectedSocialMedia){
    case socialMediaTypes.YouTube:
      return regexPatterns.youtubeURL;
    case socialMediaTypes.Instagram:
        return regexPatterns.instagramURL;
    case socialMediaTypes.TikTok:
          return regexPatterns.tiktokURL;
    default:
      return regexPatterns.twitchURL;
  }
};

export const calculateTopKey = <T extends string>(values: Record<T, number>): T | 'N/A' => {
  const entries = Object.entries(values) as [T, number][];

  if (entries.length === 0) {
    return 'N/A';
  }

  let topKey: T | 'N/A' = 'N/A';
  let maxValue = -Infinity;

  for (const [key, value] of entries) {
    if (value > maxValue) {
      maxValue = value;
      topKey = key;
    }
  }
  return topKey;
}

export const addToDictionary = (dict: { [x: string]: any; hasOwnProperty: (arg0: any) => any; }, key: string | number, value: any) => {
  if (dict.hasOwnProperty(key)) {
    dict[key] += value;
  } else {
    dict[key] = value;
  }
};

export const getCollabDescription = (socialMediaType: string, collabType: string): string => {
  return collabDescriptions[socialMediaType][collabType];     
}